<script>
import {mapGetters} from "vuex";
import {ValidationProvider} from "vee-validate";

export default {
    name: "Bank",
    components: {
        ValidationProvider
    },

    props: {
        data: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            editingFields: [],
            fields: {
                'bank_name': "required|min:1|max:255",
                'bank_address': "min:1|max:255",
                'bik': "min:1|max:255",
                'swift': "min:1|max:255",
                'kpp': "min:1|max:255",
                'account_number': "min:1|max:255",
                'correspondent_account_number': "min:1|max:255",
                'currency': "min:1|max:255",
                'comment': "min:1|max:255"
            }
        }
    },

    computed: {
        ...mapGetters({
            userBlock: 'userBlock'
        })
    },

    methods: {
        showInput(field) {
            this.editingFields.push(field);
        },

        saveBank() {
            this.$emit('save-bank', this.data);
        },

        hideInput(field, valid) {
            if(!valid) {
                return;
            }

            if (field) {
                this.editingFields = this.editingFields.filter(item => item !== field);
            } else {
                this.editingFields = [];
            }

            this.saveBank();
        },

        delBank() {
            this.$emit('del-bank', this.data);
        }
    }
}
</script>

<template>
    <div :key="data.id" class="bank">
        <v-row v-for="(validationRules, field) in fields" :key="field">
            <v-col cols="12" md="6" class="client_title">{{ $t('uved_bank.' + field) }}</v-col>
            <v-col cols="12" md="6" class="client_text">
                <div v-if="!(editingFields.includes(field))" @click="showInput(field)">
                    <span v-if="data[field] && data[field] !== 'null'">{{ data[field] }}</span>
                    <span v-else class="client_no_text">{{ $t('enter_text') }}</span>
                </div>
                <div v-else>
                    <ValidationProvider :ref="data[field]" :rules="validationRules" v-slot="{ errors, valid }">
                        <v-text-field
                            :error="!valid"
                            :error-messages="errors"
                            :label="$t('enter_text')"
                            @focusout="hideInput(field, valid)"
                            autofocus
                            v-model="data[field]"
                            type="text"
                            hide-details
                            solo
                            flat
                            dense
                            color="primary"
                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                            clearable
                        ></v-text-field>
                    </ValidationProvider>
                </div>
            </v-col>
        </v-row>
        <v-icon class="mt-3" @click="delBank" :title="$t('delete')" size="18">
            mdi-trash-can-outline
        </v-icon>
    </div>
</template>

<style scoped>
.bank {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;

    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
</style>
