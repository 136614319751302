<template>
    <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
        <PageLayout
            class="uved-detail"
            @drop.prevent="dragover = false"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragend.prevent="dragover = false"
        >
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-row class="flex-nowrap">
                            <v-col cols="12">
                                <div class="d-flex flex-row" v-if="!show_title">
                                    <h1>{{ type_uved === 1 ? company_name : first_name + ' ' + last_name + ' ' + middle_name }}</h1>
                                    <v-icon :disabled="userBlock" v-if="!loadingUved" :color="favorite ? 'primary' : ''" size="20" class="mb-2 ml-2" @click="favoriteUpdate()"> {{ favorite ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
                                    <v-icon :disabled="!editable" v-if="!loadingUved && !show_title"  @click="show_title=true" size="20" class="mb-2 ml-2"  :title="$t('edit')" >mdi-pencil mdi-18px</v-icon>
                                    <v-icon :disabled="userBlock" v-if="!loadingUved && !show_title"  @click="deleteUved()" size="20" class="mb-2 ml-2"  :title="$t('delete')">
                                        mdi-delete-outline mdi-18px
                                    </v-icon>
                                </div>
                                <div v-else>
                                    <span v-if="type_uved === 1">
                                        <ValidationProvider ref="company_name" rules="min:1|max:255" v-slot="{ errors, valid }">
                                            <v-text-field
                                                autofocus
                                                @focusout="saveFieldTitle('company_name')"

                                                v-model="company_name" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"

                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </span>
                                    <span v-else>
                                        <ValidationProvider ref="uved_name" rules="min:1|max:255" v-slot="{ errors, valid }">

                                        <v-text-field
                                            autofocus
                                            @focusout="saveFieldTitle('uved_name')"

                                            v-model="uved_name" type="text" :error-messages="errors"
                                            :disabled="loading"
                                            :label="$t('enter_text')"

                                            hide-details
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                            clearable
                                        ></v-text-field>
                                    </ValidationProvider>
                                    </span>

                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <v-row class="client-detail__info">
                                    <v-col cols="12" sm="3" v-if="show_responsible">
                                        <Users :users="show_responsible" :disabled="userBlock" @click="dialogAddParticipants=true"/>
                                    </v-col>

                                    <v-col cols="12" sm="3" v-if="company_top && company_top.name">
                                        <v-list :disabled="userBlock" subheader two-line class="background_none py-0">
                                            <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0 cursor_pointer" @click="showCompany(company_top.uuid)">
                                                    <img v-if="company_top.photo !== '/img/avatar.png'" :src="company_top.photo" :alt="company_top.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{'settingsIcon'}}
                                                    </v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="company_top.name" class="cursor-pointer" @click="showCompany(company_top.uuid)"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="formatPhoneNumber(company_top.phone)"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="3" v-if="created_at">
                                        <v-list class="background_none py-0">
                                            <v-list-item  class="mx-0 px-0" >
                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title  v-text="$t('date_created')"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="created_at"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>

                                    <v-col cols="12" sm="3"  class="d-flex justify-center justify-sm-end align-self-center">
                                        <ValidationProvider ref="deal_status" rules="required" v-slot="{ errors, valid }">
                                            <v-select
                                                v-model="type_client"
                                                :disabled="!editable || loading"
                                                class="my-0 py-0 select_border_none"
                                                :items="typeClientItems"
                                                :error-messages="errors"
                                                single-line
                                                hide-details
                                                color="primary"
                                                item-text="name"
                                                item-value="id"
                                                @change="saveUvedField('type_client', type_client)"
                                                :label="$t('type_client')"
                                            ></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <div class="text-info">
                                            <div class="text-info__label">{{$t('description')}}</div>
                                            <div class="text-info_-value" v-if="!text_uved_edit">
                                                <v-hover :disabled="userBlock" v-slot="{ hover }">
                                                    <div v-if="text_uved !== ''" @click="text_uved_edit = true" class="description_text cursor_pointer">{{text_uved}}
                                                        <v-btn v-if="hover"
                                                               icon
                                                               x-small
                                                               @click="text_uved_edit = true"
                                                        >
                                                            <v-icon color="primary">
                                                                mdi-pencil mdi-18px
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <div v-else-if="editable">
                                                        <span class="add_text" :disabled="userBlock" @click="text_uved_edit = true">{{ $t("add_text") }}</span>
                                                    </div>
                                                </v-hover>
                                            </div>
                                            <div class="text-info_-value" v-if="text_uved_edit && editable">
                                                <v-card elevation="0" class="m-0 p-0">
                                                    <v-card-text class="my-0 py-0 mx-0 px-0">
                                                        <v-textarea
                                                            v-model="text_uved"
                                                            type="text"
                                                            class="input_textarea"
                                                            auto-grow
                                                            :disabled="userBlock || loading"
                                                            no-details
                                                            outlined
                                                            hide-details
                                                        ></v-textarea>
                                                        <v-card-actions class="px-0 mx-0">
                                                            <v-btn
                                                                :disabled="userBlock"
                                                                color="primary"
                                                                class="infinity_button"
                                                                @click="UvedTextUpdate()"
                                                            >
                                                                {{$t('save')}}
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                class="infinity_button_text px-0"
                                                                @click="text_uved_edit = false"
                                                            >
                                                                {{$t('cancel')}}
                                                            </v-btn>

                                                        </v-card-actions>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <ResponsiveTabs>
                            <ResponsiveTab>
                                <template v-slot:btn-after>
                                    <div class="tab__btns">
                                        <v-icon color="secondary" size="20" :disabled="!editable" @click="linkTask">mdi-link</v-icon>
                                        <v-icon color="secondary" size="20" @click="addTask">mdi-plus-circle-outline</v-icon>
                                    </div>
                                </template>
                                <template v-slot:btn>
                                    <span>{{ $t('tasks') }}</span>
                                    <template v-if="totalTasks > 0">: <span class="text-secondary">{{ totalTasks }}</span></template>
                                </template>
                                <template v-slot>
                                    <v-data-table
                                        :headers="headersTask"
                                        :items="tasksItems"
                                        :loading="loadingTask"
                                        :locale="lang"
                                        :loading-text="$t('loading_please_wait')"
                                        hide-default-footer
                                        :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                        class="elevation-0"
                                        :items-per-page="perPage"
                                        :item-class="rowClass"
                                    >
                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass Table5">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span>{{h.text}}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                        <template v-slot:item.title="{ item }" class="text">
                                            <v-btn text plain :to="'/task/' + item.uuid + '/show'"  class="cursor-pointer font_weight_600 button_link"  :style="'color:'+ item.color+'!important'">
                                                {{ item.title }}
                                            </v-btn>
                                        </template>
                                        <template v-slot:item.task_status="{ item }">
                                            <div>{{ item.task_status }}</div>
                                        </template>
                                        <template v-slot:item.deadline="{ item }">
                                            <div>{{ item.deadline ?  $moment(item.deadline).format('DD.MM.YYYY HH:mm') : '' }}</div>
                                        </template>
                                        <template v-slot:item.created_at="{ item }">
                                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                        </template>

                                        <template v-slot:item.admin.name="{ item }">
                                            <div>
                                                <v-list subheader two-line class="background_none py-0">
                                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{'settingsIcon'}}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </template>
                                    </v-data-table>
                                </template>
                            </ResponsiveTab>
                            <ResponsiveTab>
                                <template v-slot:btn>
                                    {{$t('accounts')}}{{totalInvoices > 0 ? ': ' + totalInvoices : ''}}
                                </template>
                                <template>
                                    <v-data-table :headers="headersInvoice" :items="invoiceItems"
                                                  :loading="loadingInvoice" :locale="lang"
                                                  :loading-text="$t('loading_please_wait')"
                                                  hide-default-footer

                                                  :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                  class="elevation-0"
                                                  :item-class="rowClass2">

                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass Table7">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span v-if="h.value !== 'download'" >{{h.text}}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                        <template v-slot:item.download="{ item }">
                                            <div>
                                                <v-icon color="primary" @click="downloadFile(item.request_scanned)" :title="$t('download')">mdi-cloud-upload</v-icon>
                                            </div>
                                        </template>
                                        <template v-slot:item.amount="{ item }">
                                            <div :class="item.deleted ? 'red--text' : ''">
                                        <span  class="cursor-pointer font_weight_600" @click="editInvoice(item)" >
                                            {{ item.amount }}
                                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                        </span>
                                            </div>
                                        </template>
                                        <template v-slot:item.date="{ item }">
                                            <div>{{ $moment(item.date).format('DD.MM.YYYY') }}</div>
                                        </template>
                                        <template v-slot:item.number_deal="{ item }">
                                            <div v-if="item.number_deal"  class="cursor-pointer" @click="showDeal(item.deal_uuid)">{{ item.number_deal }}</div>
                                            <div v-else>{{ $t('no') }}</div>
                                        </template>
                                        <template v-slot:item.invoice_status="{ item }">
                                            <div class="cursor-pointer" @click="openDialogInvoiceStatus(item)">{{ item.invoice_status }}</div>
                                        </template>
                                        <template v-slot:item.company.name="{ item }">
                                            <div>
                                                <v-list subheader two-line class="background_none py-0">
                                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0 cursor_pointer" @click="showCompany(item.company.uuid)">
                                                            <img v-if="item.company.photo !== '/img/avatar.png'" :src="item.company.photo" :alt="item.company.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{'settingsIcon'}}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="item.company.name" class="cursor-pointer" @click="showCompany(item.company.uuid)"></v-list-item-title>
                                                            <v-list-item-subtitle v-text="formatPhoneNumber(item.company.phone)"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </template>
                                        <template v-slot:item.admin.name="{ item }">
                                            <div>
                                                <v-list subheader two-line class="background_none py-0">
                                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{'settingsIcon'}}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </template>

                                    </v-data-table>
                                </template>
                            </ResponsiveTab>
                            <ResponsiveTab>
                                <template v-slot:btn>
                                    {{ $t('deals') }} {{totalDeals > 0 ? ': ' + totalDeals : '' }}
                                </template>
                                <template v-slot:btn-after>
                                    <div class="tab__btns">
                                        <v-icon color="secondary" :disabled="userBlock" @click="addDeal" size="20" class="add_icon">
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                </template>
                                <template>
                                    <v-data-table
                                        :headers="headersDeal"
                                        :items="dealItems"
                                        :loading="loadingDealOrders"
                                        :locale="lang"
                                        :items-per-page="perPage"
                                        :loading-text="$t('loading_please_wait')"
                                        hide-default-footer
                                        :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                        class="elevation-0"
                                        :item-class="rowClass3"
                                    >
                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass Table5">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span>{{h.text}}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                        <template v-slot:item.number="{ item }">
                                            <div :class="item.deleted ? 'red--text' : ''">
                                            <span  class="cursor-pointer font_weight_600" @click="showDeal(item.uuid)" >
                                                {{ item.number }}
                                                {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                            </span>
                                            </div>
                                        </template>
                                        <template v-slot:item.deal_status="{ item }">
                                            <div>{{ item.deal_status }}</div>
                                        </template>
                                        <template v-slot:item.created_at="{ item }">
                                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                        </template>
                                        <template v-slot:item.admin="{ item }">
                                            <div>
                                                <v-list subheader two-line class="background_none py-0">
                                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                            <v-icon v-else color="primary" size="26">
                                                                ${{'settingsIcon'}}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </template>
                                        <template v-slot:item.active="{ item }">
                                            <v-edit-dialog :return-value.sync="item.active" large persistent
                                                           @save="activeDeal(item)" :save-text="$t('save')"
                                                           :cancel-text="$t('cancel')">
                                                <div class="cursor-pointer">{{ item.active ? $t('yes') : $t('no')}}</div>
                                                <template v-slot:input>
                                                    <v-select v-model="item.active" :items="itemYN" item-text="name"
                                                              item-value="id"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                              color="primary"
                                                              class="pt-5 elevation-0 rounded-lg input_text"
                                                    ></v-select>
                                                </template>
                                            </v-edit-dialog>
                                        </template>
                                    </v-data-table>

                                </template>
                            </ResponsiveTab>
                            <ResponsiveTab>
                                <template v-slot:btn>
                                    {{ $t('cases') }}
                                </template>
                                <template>
                                    <calendar-case ref="calendarCase"></calendar-case>
                                </template>
                            </ResponsiveTab>
                            <ResponsiveTab v-if="can(['client_module'])">
                                <template v-slot:btn>
                                    {{ $t('chat') }}
                                </template>
                                <template>
                                    <v-list two-line class="background_color_transparent">
                                        <v-list-item-group
                                            v-model="selected"
                                            class="my-0 py-0"
                                        >
                                            <v-list-item
                                                v-for="(item, i) in topicItems"
                                                :key="i"


                                                :class="'rounded-lg  ' + item.class + ' ' + ($vuetify.breakpoint.xsOnly ? 'pl-15' : 'px-3')"
                                                class="my-4 height_list_item"
                                                @click="openChat(item)"
                                            >
                                                <template v-slot:default="{ active }">
                                                    <v-app-bar
                                                        elevation="0"
                                                        absolute
                                                        class="my-5 py-0"
                                                        height="0"
                                                        v-if="!($vuetify.breakpoint.xsOnly)"
                                                    >
                                                        <v-spacer></v-spacer>
                                                        <v-icon class="icon_width " v-if="item.active">${{'lockOpenIcon'}}</v-icon>
                                                        <v-icon v-else class="icon_width">${{'lockIcon'}}</v-icon>

                                                    </v-app-bar>
                                                    <v-list-item-avatar size="58" class="border1_B3B3B3"   v-if="!($vuetify.breakpoint.xsOnly)">
                                                        <img v-if="item.last_user.photo " :src="item.last_user.photo" >
                                                        <v-icon v-else  size="26" class="color_B3B3B3">
                                                            ${{'settingsIcon'}}
                                                        </v-icon>
                                                    </v-list-item-avatar>

                                                    <v-list-item-content class="ml-6 topic_padding_top0">
                                                        <v-row v-if="($vuetify.breakpoint.xsOnly)" class="my-0 py-0">
                                                            <v-col cols="12" style="position: absolute;top:3px;left:-3px">
                                                                <v-avatar size="58" class="border1_B3B3B3" >
                                                                    <img v-if="item.last_user.photo " :src="item.last_user.photo" >
                                                                    <v-icon v-else  size="26" class="color_B3B3B3">
                                                                        ${{'settingsIcon'}}
                                                                    </v-icon>
                                                                </v-avatar>
                                                            </v-col>
                                                            <v-col cols="12" style="position: absolute; bottom: 3px; left: 12px">
                                                                <v-icon class="icon_width" size="35" v-if="item.active">${{'lockOpenIcon'}}</v-icon>
                                                                <v-icon v-else size="50" class="icon_width">${{'lockIcon'}}</v-icon>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="topic_margin_top0">
                                                            <v-col cols="12"  md="4" sm="4" lg="6" class="padding_top10_bottom0" >
                                                                <v-list-item-title class="topic_title" v-text="item.title"></v-list-item-title>
                                                                <v-list-item-subtitle
                                                                    style="max-width: 400px"
                                                                    :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                                    v-if="(item.last_message && item.last_message.type && item.last_message.type==='text') ">
                                                                    <span class="font-weight-700">{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}</span>
                                                                    {{ item.last_message.value }}

                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle
                                                                    style="max-width: 300px"
                                                                    :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                                    v-if="(item.last_message && item.last_message.type && item.last_message.type==='file') ">
                                                                    <span class="font-weight-700">{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}</span>
                                                                    {{item.last_message.file_name}}
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle
                                                                    style="max-width: 400px"
                                                                    :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                                    v-if="(item.last_message && item.last_message.type && item.last_message.type==='image') ">
                                                            <span class="font-weight-700">{{item.last_user.my ? $t('from_you') :  $t('from_name', {"name": item.last_user.name})}}
                                                                <v-img max-width="100" :src="item.last_message.value ? item.last_message.value : userAvatar" ></v-img>
                                                            </span>
                                                                </v-list-item-subtitle>
                                                            </v-col>
                                                            <v-col cols="12"  md="4" sm="4" lg="3" class="padding_top10_bottom0">
                                                                <v-list-item-title class="topic_text">
                                                                    {{$t('last_message')}}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle class="topic_title">
                                                                    {{item.last_date}}
                                                                </v-list-item-subtitle>
                                                            </v-col>
                                                            <v-col cols="12" md="4" sm="4" lg="3" class="padding_top10_bottom0">
                                                                <v-list-item-title class="topic_text">
                                                                    {{$t('application_status')}}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle class="topic_title" >
                                                                    {{item.bot ? $t('system_message') : (item.active ? $t('open_application') : $t('closed_application')) }}
                                                                </v-list-item-subtitle>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </template>
                            </ResponsiveTab>
                            <ResponsiveTab v-if="can(['client_module'])">
                                <template v-slot:btn>
                                    {{ $t('tracking') }}
                                </template>
                                <template>
                                    <v-card
                                        elevation="0"
                                        :hover="true"
                                        v-for="(item, i) in trackings"
                                        :key="'tracking_'+i"
                                        class="mb-7 rounded-lg"
                                        :class="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 'text-center' :''"
                                        @click="showDetailsApplication(item)"
                                        color="#F5F5F5"
                                    >
                                        <v-card-text class="px-0 py-0">
                                            <v-row class="px-5 pb-2">
                                                <v-col cols="12" sm="4" :class="$vuetify.breakpoint.xsOnly ? 'text-left deal_col_50' : ''">
                                                    <div class="mb-4 deal_title">
                                                        <div class="deal_text mb-1">{{$t('date_created')}}</div>
                                                        {{ item.date_operation ? $moment(item.date_operation).format('DD.MM.YYYY') : '' }}
                                                    </div>
                                                    <div class="mb-2 deal_title">
                                                        <div class="deal_text mb-1">{{$t('container_number')}}</div>
                                                        {{item.container_number ? item.container_number : $t('no_data') }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" sm="8" :class="$vuetify.breakpoint.xsOnly ? 'text-right deal_col_50' : ''">
                                                    <div class="mb-4 deal_title">
                                                        <div class="deal_text mb-1">{{$t('direction')}}</div>
                                                        {{item.departure}} - {{item.destination}}
                                                    </div>
                                                    <div v-if="item.day > 0" class="deal_title">
                                                        <div class="deal_text mb-1">{{$t('in_operation_for')}}</div>
                                                        {{ $t('for_day', {"day":item.day})}}
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" v-if="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)">
                                                    <v-btn type="button"
                                                           outlined
                                                           color="primary"
                                                           class="rounded-lg but_enter"
                                                           block
                                                           :height="$vuetify.breakpoint.xsOnly ? 45 : 35">
                                                        {{ $t('details') }}
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="12" class="pb-7" v-if="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)">
                                                    <v-row >
                                                        <v-col cols="12">
                                                            <ul class="pod_status d-flex">
                                                                <template v-for="(status_item, i) in statusItems"  >
                                                                    <li :key="'status'+status_item.id" class="li1"><div class="node " :class="item.status_id >= status_item.id ? 'blue_point' : 'grey_point'"></div><p :class="(i+1) === statusItems.length ? 'li_last' : ''"><span :class="item.status_id >= status_item.id ? 'blue_color' : ''" >{{status_item.name}}</span></p></li>
                                                                    <li :key="'status'+status_item.id + '-' + status_item.id" v-if="(i+1) !== statusItems.length" class="my_divider " :class="item.status_id > status_item.id ? 'blue_line' : 'grey_line'"></li>
                                                                </template>
                                                            </ul>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </ResponsiveTab>

                            <ResponsiveTab v-for="tab in tabs" :key="tab.id" :title="tab.name">
                                <template>
                                    <FormTabField entity_type="uved" :entity_uuid="uved_uuid" :key="tab.id" :tab="tab"  />
                                </template>
                            </ResponsiveTab>
                        </ResponsiveTabs>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" >
                        <v-card>
                            <v-card-text class="px-0 mx-0">
                                <v-row class="px-0 mx-0">
                                    <v-col cols="12" class="px-0 mx-0" v-if="chatMessagesDate.length !== 0">
                                        <v-card ref="scrollContainer"  id="book" flat  max-height="800" class="h_scroll px-0 mx-0">
                                            <v-card-text class="py-0 my-0 px-0 mx-0">
                                                <div v-for="(chatMessages, date) in chatMessagesDate" :key="date">
                                                    <div class="date_day_week">{{ getDateDayWeek(date) }}</div>

                                                    <v-col v-for="msg in chatMessages" :key = "msg.id" class="py-0 my-0">
                                                        <v-card :id="'div_'+msg.uuid" flat class="py-1 mt-7 px-3 mx-0" >

                                                            <v-card class="d-flex"  flat >
                                                                <div>
                                                                    <v-avatar size="36" class="border_blue px-0 mx-0">
                                                                        <img v-if="msg.photo !== '/img/avatar.png' && msg.photo !== '' && msg.photo !== null" :src="msg.photo" :alt="msg.name">
                                                                        <v-icon v-else color="primary" size="22">
                                                                            ${{'settingsIcon'}}
                                                                        </v-icon>
                                                                    </v-avatar>
                                                                </div>

                                                                <div class="title_subtitle mr-auto px-2">
                                                                    <div>
                                                                        <span class="v-list-item__title">{{msg.name}}</span>
                                                                        <v-menu offset-y>
                                                                            <template v-slot:activator="{ on, attrs }" >
                                                                                <v-btn icon
                                                                                       v-bind="attrs"
                                                                                       v-on="on"
                                                                                       x-small
                                                                                >
                                                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <v-list>
                                                                                <v-list-item
                                                                                    v-for="(item, index) in message_menu"  :key="index"
                                                                                    @click="handleClick(item.click, msg, date)"
                                                                                >
                                                                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                                                                </v-list-item>
                                                                            </v-list>
                                                                        </v-menu>
                                                                    </div>
                                                                    <div class="v-list-item__subtitle">{{formatPhoneNumber(msg.phone)}}</div>
                                                                </div>

                                                                <div class="chat_time">
                                                                    <v-icon class="mx-0 px-0 pr-1 my-0 py-0" right>
                                                                        {{ msg.delivered ? 'mdi-check-all' : 'mdi-check'}}
                                                                    </v-icon>
                                                                    {{ $moment(msg.created_at).format('HH:mm') }}
                                                                </div>
                                                            </v-card>


                                                            <v-row class="chat_text_row">
                                                                <v-col cols="12" class="px-2 pb-1 " >
                                                                    <div v-if="msg.reply" class="pb-2">
                                                                        <div v-if="msg.reply.type === 'text'" class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                                                                            <div class="mr-auto pt-2 px-2">
                                                                                <span class="reply_name">{{ msg.reply.name }}</span>
                                                                                <span class="font-size-10 reply_name">{{ msg.reply.value }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="msg.reply.type === 'image'" class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                                                                            <v-img v-if="msg.reply.type === 'image'"
                                                                                   class="my-0 py-0 ml-2"
                                                                                   max-width="50"
                                                                                   :src="msg.reply.value"
                                                                            >
                                                                            </v-img>
                                                                            <div class="pt-2 px-2">
                                                                                <span class="reply_name">{{ msg.reply.name }}</span>
                                                                                <span class="reply_name font-size-10">
                                                                                {{ $t('photo') }}
                                                                                <v-icon left x-small>
                                                                                   mdi-camera-outline
                                                                                </v-icon>
                                                                            </span>
                                                                            </div>

                                                                        </div>
                                                                        <div v-if="msg.reply.type === 'file'"  class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                                                                            <div class="mr-auto pt-2 px-2">
                                                                                <span class="reply_name">{{ msg.reply.name }}</span>
                                                                                <span class="reply_name font-size-10">
                                                                                {{msg.reply.file_name}}
                                                                                <v-icon left x-small>
                                                                                   mdi-paperclip
                                                                                </v-icon>
                                                                            </span>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="msg.reply.type === 'sound'"  class="rounded-lg rounded-r-0 py-2 d-inline-flex reply_background">
                                                                            <div class="mr-auto pt-2 px-2">
                                                                                <span class="reply_name">{{ msg.reply.name }}</span>
                                                                                <span class="reply_name font-size-10 pt-1">
                                                                                    <av-waveform
                                                                                        class="canvas_hide"
                                                                                        :canv-width="0"
                                                                                        :canv-height="0"
                                                                                        :audio-src="msg.comment.value">
                                                                                    </av-waveform>
                                                                                    {{$t('record')}}
                                                                                    <v-icon left x-small>
                                                                                      mdi-play-circle-outline
                                                                                    </v-icon>
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div v-if="msg.comment.type === 'text'" class="chat_text">
                                                                        {{ msg.comment.value }}
                                                                        <div v-if="getTranslation(msg.comment.translations)" class="chat_text mb-0">
                                                                            <p style="background-color: #F5F5F5;padding: 20px;" class="mb-0">
                                                                                {{ getTranslation(msg.comment.translations) }}
                                                                            </p>
                                                                            <p class="font-size-10">{{$t('transferred_via')}}</p>
                                                                        </div>
                                                                        <v-btn v-else  icon :disabled="loading" @click="translation(msg.comment, msg.uuid)" :title="$t('translate')">
                                                                            <v-icon>mdi-google-translate</v-icon>
                                                                        </v-btn>
                                                                    </div>
                                                                    <div v-if="msg.comment.type === 'image'" class="chat_text">
                                                                        <v-img
                                                                            class="mb-3 cursor_pointer"
                                                                            max-width="250"
                                                                            :src="msg.comment.value"
                                                                            @click="ImageView(msg)"
                                                                        >

                                                                            <v-row v-if="msg.delivered ? false : true"
                                                                                   class="fill-height ma-0"
                                                                                   align="center"
                                                                                   justify="center"

                                                                            >
                                                                                <v-progress-circular
                                                                                    indeterminate
                                                                                    color="grey lighten-5"
                                                                                ></v-progress-circular>
                                                                            </v-row>
                                                                        </v-img>
                                                                    </div>
                                                                    <div v-if="msg.comment.type === 'file'" class="chat_text">
                                                                        <v-btn
                                                                            color="primary"
                                                                            class="white--text"
                                                                            @click="downloadFileMessage(msg.comment.value, msg.comment.file_name)"
                                                                        >
                                                                            <v-icon
                                                                                left
                                                                                dark
                                                                            >
                                                                                {{ !msg.delivered ? 'mdi-loading mdi-spin' : 'mdi-cloud-upload'}}
                                                                            </v-icon>

                                                                            {{ msg.comment.file_name }} ({{ (Number(msg.comment.size) / 1000).toFixed(1) + ' KB' }} )

                                                                        </v-btn>
                                                                    </div>
                                                                    <div v-if="msg.comment.type === 'sound'" class="chat_text">
                                                                        <av-waveform
                                                                            class="canvas_hide"
                                                                            :canv-width="0"
                                                                            :canv-height="0"
                                                                            :audio-src="msg.comment.value">
                                                                        </av-waveform>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>

                                                    </v-col>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" class="ma-0 pa-0 client-detail__reply" :style="chatMessagesDate.length !== 0 ? 'border-top: solid 1px #F3F3F3 !important;' : '' ">
                                        <v-card flat>
                                            <v-toolbar
                                                class="mb-2 toolbar_reply rounded-0"
                                                height="60px"
                                                elevation="0"
                                                v-if="toolbar_reply"
                                            >
                                                <div class="reply" >
                                                    <div class="pt-0 my-0" v-if="reply_msg.type === 'text'">
                                                        <span class="reply_name"> {{ reply_name}} </span>
                                                        <span class="font-size-10">{{ reply_msg.value }}</span>
                                                    </div>
                                                    <div v-if="reply_msg.type === 'image'" class="d-flex mx-0 px-0">
                                                        <v-img v-if="reply_msg.type === 'image'"
                                                               class="ml-1 my-0"
                                                               max-width="50"
                                                               :src="reply_msg.value"

                                                        >
                                                        </v-img>
                                                        <div class="mr-auto pt-0 px-2">
                                                            <span class="reply_name">{{ reply_name}}</span>
                                                            <span class="reply_name font-size-10">
                                                           {{$t('photo')}}
                                                           <v-icon left x-small >
                                                               mdi-camera-outline
                                                           </v-icon>
                                                       </span>
                                                        </div>




                                                    </div>
                                                    <div v-if="reply_msg.type === 'file'" class="my-0">
                                                        <div class="pt-0">
                                                            <span class="reply_name">{{ reply_name}}</span>
                                                            <span class="font-size-10">
                                                           {{reply_msg.file_name}}
                                                           <v-icon left x-small>
                                                               mdi-paperclip
                                                           </v-icon>
                                                       </span>
                                                        </div>



                                                    </div>
                                                    <div v-if="reply_msg.type === 'sound'" class="my-0">
                                                        <div class="pt-0">
                                                            <span class="reply_name">{{ reply_name}}</span>
                                                            <span class="font-size-10">
                                                               {{$t('record')}}
                                                               <v-icon left x-small>
                                                                   mdi-play-circle-outline
                                                               </v-icon>
                                                           </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <v-spacer></v-spacer>
                                                <v-toolbar-items>
                                                    <v-btn
                                                        icon
                                                        @click="replyClose"
                                                    >
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                </v-toolbar-items>
                                            </v-toolbar>

                                            <CommentInput
                                                v-if="uved_active"

                                                v-model="text"
                                                @send="SentMessage"
                                            />
                                        </v-card>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-file-input hide-input prepend-icon=""
                              v-model="files"
                              id="file"
                              multiple
                              @change="previewFiles"
                >
                </v-file-input>

                <v-dialog
                    v-model="dialog_file"
                    scrollable
                    persistent
                    max-width="700px"
                    transition="dialog-bottom-transition"

                >
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('send_files', {'count': document_urls.length}) }}</span>
                            </div>
                            <div>
                                <v-icon @click="dialog_file = false;document_urls=[];documents=[]" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text  style="height: 300px;">
                            <v-list subheader>
                                <v-list-item  v-for="document in document_urls" :key="document.id">
                                    <v-list-item-icon>
                                        <v-img
                                            max-width="90"
                                            :alt="`${document.name}`"
                                            :src="document.url"
                                            max-height="60"
                                        ></v-img>
                                        <v-icon
                                            x-large
                                            v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title v-text="document.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-icon class="d-flex align-self-center mt-2">
                                        <v-btn
                                            icon
                                            @click="deleteFile(document)"
                                        >
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>

                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>


                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="SentMessageFile"
                            >
                                {{$t('sent')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog
                    v-model="accompanying_documents_dialog"
                    scrollable
                    persistent
                    max-width="500px"
                    transition="dialog-bottom-transition"


                >
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('send_files', {'count': accompanying_documents_preview.length}) }}</span>
                            </div>
                            <div>
                                <v-icon @click="accompanying_documents_dialog = false;accompanying_documents_preview=[];accompanying_documents=[]" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text  style="height: 300px;">
                            <v-list subheader>
                                <v-list-item  v-for="document in accompanying_documents_preview" :key="document.id">
                                    <v-list-item-icon>
                                        <v-img
                                            max-width="90"
                                            :alt="`${document.name}`"
                                            :src="document.url"
                                            max-height="60"
                                        ></v-img>
                                        <v-icon
                                            x-large
                                            v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title v-text="document.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-icon class="d-flex align-self-center mt-2">
                                        <v-btn
                                            icon
                                            @click="deleteAccompanyingDocuments(document)"
                                        >
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>

                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>


                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="saveUvedFile"
                            >
                                {{$t('save')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                    v-model="dialogInvoiceStatus"
                    class="dialog_body"
                >
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('invoice_editing') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeInvoiceStatus()" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text class="pa-2 pb-0">
                            <v-col cols="12">
                                <ValidationProvider ref="invoice_status_edit" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="invoice_status_edit" :disabled="loading"
                                              :items="invoiceStatusEditItems" :error-messages="errors"
                                              :error="!valid"
                                              item-text="name" item-value="id"
                                              :label="$t('invoice_status')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              hide-details
                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                    ></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                @click="paymentConfirmation()"
                            >{{ $t('edit') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                    v-model="dialogStatusApproval"
                    class="dialog_body"
                >
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('reason_for_rejecting_uved') }}</span>
                            </div>
                            <div>
                                <v-icon @click="dialogStatusApproval = false" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text class="pa-2 pb-0">
                            <v-col cols="12">
                                <ValidationProvider ref="reject_text" rules="required|min:10|max:62000"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea
                                        solo
                                        :label="$t('write_reason_for_the_rejection')"
                                        auto-grow
                                        outlined
                                        rows="8"
                                        row-height="25"
                                        v-model="reject_text"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        class="input_textarea"
                                        no-details
                                        hide-details
                                        full-width
                                    >
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="addStatusApproval('reject')"
                            >{{ $t('reject') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="700"
                    v-model="dialogAddParticipants"
                >
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('edit_participants') }}</span>
                            </div>
                            <div>
                                <v-icon @click="dialogAddParticipants = false" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text >
                            <v-row >
                                <v-col class="py-0 pt-5" cols="12">
                                    <ValidationProvider ref="responsible" rules="required|min:1" v-slot="{ errors, valid }">
                                        <UserSelection
                                            multiple
                                            v-model="responsible"
                                            id="responsible"
                                            :label="$t('responsible')"
                                            :error="!valid"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            clearable
                                        />
                                    </ValidationProvider>
                                </v-col>

                            </v-row>

                        </v-card-text>
                        <v-card-actions class="pt-5">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="editParticipants()">
                                {{ $t('edit') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog
                    v-model="dialogDetailTracking"
                    :width="!$vuetify.breakpoint.xsOnly ? 700 : '100%'"
                    overlay-color = "#edebeb"
                    :content-class="!$vuetify.breakpoint.xsOnly ? 'rounded-lg border1_B3B3B3' : ''"
                    transition="dialog-bottom-transition"

                    :fullscreen="$vuetify.breakpoint.xsOnly"
                    :hide-overlay="$vuetify.breakpoint.xsOnly"
                    :scrollable="$vuetify.breakpoint.xsOnly"

                >
                    <v-card>
                        <v-card-title class="deal_dialog_padding">
                            <v-row class="mx-0 px-0 py-0 my-0">
                                <v-col cols="12" class="mx-0 px-0 py-0 my-0">
                                    <v-row>
                                        <v-col cols="10" class="ma-auto">{{$t('more_application')}}</v-col>
                                        <v-col cols="2" class="text-right"><v-icon size="35" @click="close_info()">${{'closeIcon'}}</v-icon></v-col>
                                    </v-row>
                                    <div class="text_left">
                                        <v-chip  v-for="(item, z) in data_info" :key="'data_info'+z"
                                                 small
                                                 class="mr-2"
                                                 :class="item.class"
                                                 :color="item.color"
                                                 label
                                                 outlined
                                                 @click="show_info(item.type)"
                                        >{{item.name}}</v-chip>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text class="mx-0 px-0 py-0 my-0">
                            <v-row class="mx-0 px-0 py-0 my-0">
                                <v-col cols="12" class="deal_dialog_padding pt-0"  v-if="info_type==='data'">
                                    <v-row class="mx-0 px-0 py-0 my-0">
                                        <v-col cols="12" md="6" class="mx-0 px-0 py-0 my-0">
                                            <v-row class="mx-0 px-0 py-0 my-0">
                                                <v-col cols="12" class="deal_in_detail">{{ $t('application_number') }}: <b>{{ detail_tracking.number ? detail_tracking.number :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12" class="deal_in_detail">{{ $t('container_number') }}: <b>{{ detail_tracking.container_number ? detail_tracking.container_number :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12" class="deal_in_detail">{{ $t('departures') }}: <b>{{ detail_tracking.departure ? detail_tracking.departure :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12" class="deal_in_detail">{{ $t('destination') }}: <b>{{ detail_tracking.destination ? detail_tracking.destination :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12" class="deal_in_detail">{{ $t('type_container') }}: <b>{{ detail_tracking.type_container ? detail_tracking.type_container :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12" class="deal_in_detail">{{ $t('type_transportation') }}: <b>{{ detail_tracking.type_transport ? detail_tracking.type_transport :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12" class="deal_in_detail">{{ $t('download_type') }}: <b>{{ (detail_tracking.loading_type && detail_tracking.loading_type.name) ? detail_tracking.loading_type.code +' '+ detail_tracking.loading_type.name :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12" class="deal_in_detail">{{ $t('application_date') }}: <b>{{ detail_tracking.created_at ? $moment(detail_tracking.created_at).format('YYYY-MM-DD HH:mm') : $t('no_data')  }}</b></v-col>

                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-row>
                                                <v-col cols="12" class="deal_in_detail">{{ $t('date_operation') }}: <b>{{ detail_tracking.date_operation  ? $moment(detail_tracking.date_operation).format('YYYY-MM-DD HH:mm') : $t('no_data')  }}</b></v-col>
                                                <v-col cols="12" class="deal_in_detail">{{ $t('date_of_arrival_at_the_border') }}: <b>{{ detail_tracking.date_of_arrival_at_border ? $moment(detail_tracking.date_of_arrival_at_border).format('YYYY-MM-DD HH:mm') :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12" class="deal_in_detail">{{ $t('arrival_date_at_destination') }}: <b>{{ detail_tracking.arrival_date_at_destination ? $moment(detail_tracking.arrival_date_at_destination).format('YYYY-MM-DD HH:mm') :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12"  md="6" lg="12" class="deal_in_detail">{{ $t('application_status') }} : <b>{{ detail_tracking.status ? detail_tracking.status :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12"  md="6" lg="12" class="deal_in_detail">{{ $t('distance_to_destination') }}: <b>{{ detail_tracking.distance_to_destination ? detail_tracking.distance_to_destination :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12"  md="6" lg="12" class="deal_in_detail">{{ $t('delivered') }}: <b>{{ detail_tracking.paid == true ? $t('yes') :  $t('no')  }}</b></v-col>
                                                <v-col cols="12"  md="6" lg="12" class="deal_in_detail">{{ $t('company') }}: <b>{{ detail_tracking.company && detail_tracking.company.name ? detail_tracking.company.name  :  $t('no_data')  }}</b></v-col>
                                                <v-col cols="12"  md="6" lg="12" class="deal_in_detail">{{ $t('bin_iin') }}: <b>{{ detail_tracking.company && detail_tracking.company.bin_iin ?  detail_tracking.company.bin_iin :  $t('no_data')  }}</b></v-col>

                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" v-if="info_type==='tracking'">
                                    <v-row>
                                        <v-col cols="12" class="rounded-lg" style="background-color: #CDDAE1">
                                            <v-row>
                                                <v-col cols="12" md="4" >
                                                    <ul class="station">
                                                        <li v-if="tracking_geo.from">
                                                            <div class="node blue_point" ></div>
                                                            <p class="font-primary text_left">{{tracking_geo.from.name}} <span>{{tracking_geo.from.date_operation}}</span></p>
                                                        </li>
                                                        <li><div class="divider blue_line"></div></li>

                                                        <template v-for="(item, t) in tracking_geo.tracking"  >
                                                            <li :key="'geo'+item.id" class="text_left">
                                                                <div class="node blue_point"></div>
                                                                <p class="font-primary text_left">{{item.name}}<span>{{item.date_operation}}</span></p>
                                                            </li>
                                                            <li :key="'geo'+item.name"><div :class="(t+1)===tracking_geo.tracking.length ? 'grey_line' : 'blue_line'" class="divider"></div></li>
                                                        </template>


                                                        <li v-if="tracking_geo.to">
                                                            <div class="node grey_point"></div>
                                                            <p class="font-primary text_left">{{tracking_geo.to.name}} <span>{{tracking_geo.to.date_operation}}</span></p>
                                                        </li>

                                                    </ul>

                                                </v-col>
                                                <v-col cols="12" md="8">
                                                    <div style="height: 310px;" class="rounded-lg my-0 py-0">
                                                        <yandex-map class="py-0 my-0 py-0" v-if="coords && showMap" ref="map"
                                                                    :coords="coords"
                                                                    :controls="[]"
                                                                    :show-all-markers="showAllMarkers"
                                                                    :zoom="zoom"
                                                                    @map-was-initialized="initHandler"
                                                                    style="background-color: #ffffff">
                                                        </yandex-map>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-dialog>

                <v-dialog
                    v-model="dialog_image_view"
                    scrollable
                    fullscreen
                    v-if="!!image_view.comment"
                >
                    <v-card>
                        <v-card-title class="px-2 mx-0">
                            <span class="pl-5 text-truncate">{{ image_view.comment.file_name }}</span>
                            <v-spacer></v-spacer>
                            <v-tooltip >
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on"
                                           @click="downloadFileMessage(image_view.comment.value, image_view.comment.file_name)"
                                           icon>
                                        <v-icon>mdi-arrow-collapse-up mdi-flip-v</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('download')  }}</span>
                            </v-tooltip>
                            <v-tooltip >
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on"
                                           @click="ImageViewClose()"
                                           icon>
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('close')  }}</span>
                            </v-tooltip>
                        </v-card-title>

                        <v-card-text  style="height: 300px;">

                            <v-container fluid>
                                <v-row dense>
                                    <v-col                                >
                                        <v-card>
                                            <v-img
                                                :alt="`${image_view.comment.file_name}`"
                                                :src="image_view.comment.value"


                                            >

                                            </v-img>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>



                        </v-card-text>


                    </v-card>
                </v-dialog>

                <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                    v-model="dialogLinkTask"
                >
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('link_task') }}</span>
                            </div>
                            <div>
                                <v-icon @click="dialogLinkTask = false" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text class="pa-0">
                            <v-col class="py-0 pt-5" cols="12" sm="12">
                                <ValidationProvider ref="task" rules="min:1" v-slot="{ errors, valid }">
                                    <div class="input_label">{{$t('tasks')}}</div>
                                    <v-combobox v-model="task" :items="taskAddItems"
                                                :error="!valid"
                                                :search-input.sync="taskSearching" hide-selected
                                                item-text="title" item-value="id" :loading="loadingTasks"
                                                :label="$t('tasks')" @click:clear="taskItems=[]"
                                                @click="clearTasks" @input="clearEmptyTaskItems(task)"
                                                multiple return-object autocomplete="off"
                                                hide-details
                                                :error-messages="errors"
                                                :disabled="loading"
                                                outlined
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                clearable>
                                        <template v-slot:item="{ attrs, item, parent, selected }">
                                                <span class="search_list">
                                                    <span class="ml-2 hidden-is-empty" v-text="item.title"></span>
                                                </span>
                                        </template>
                                        <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <v-chip v-if="item === Object(item)"
                                                    class="input_chip"
                                                    v-bind="attrs" :input-value="selected"
                                                    @click:close="parent.selectItem(item)"
                                                    :color="!item.deleted ? 'green  lighten-5' : 'red  lighten-5'"
                                                    :title="!item.deleted ? $t('active') : $t('no_active')"
                                                    link close>
                                                <span v-html="item.title"></span>
                                            </v-chip>
                                        </template>
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ taskSearching ? $t('nothing_found_by', {'search': taskSearching}) : $t('nothing_found_name')}}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                                </ValidationProvider>

                            </v-col>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="addUvedToTask()">
                                {{ $t('add') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-overlay :value="dragover" z-index="10" color="#244ba8">
                    <v-row>
                        <v-col style="height: 400px;width:500px;"
                               class="grey darken-1"
                               @drop.prevent="dropFile"
                        >
                            <v-col style="height: 380px;width:490px; border: dashed 4px #fff;">
                                <v-card
                                    height="350"
                                    class="pa-2 grey darken-1 align-self-center"
                                    elevation="0"

                                >
                                    <v-col sm="12" class="text-center mt-10">
                                        <v-icon
                                            left style="font-size: 150px"

                                            class="text-center">
                                            mdi-file-upload-outline
                                        </v-icon>
                                    </v-col>
                                    <v-col sm="12" class="text-center font-weight-bold h5">{{$t('drop_files_here_to_send_them')}}</v-col>
                                </v-card>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-overlay>
            </template>

            <template v-slot:side>
                <v-col cols="12" class="pa-0" >
                    <FormRight v-for="form in forms" :key="form.sort" entity_type="uved" :entity_uuid="uved_uuid" :form="form" />
                </v-col>

                <v-col cols="12" class="pa-0" :style="forms.length > 0 ? 'margin-top: 15px' : ''">
                    <v-card class="side-block">
                        <v-card-title>
                            <div class="participants mr-auto">{{ $t('phones') }}</div>
                            <v-icon
                                size="18"
                                class="add_icon"
                                color="secondary"
                                @click="addPhone"
                                :disabled="!editable"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-card-title>
                        <v-card-text>
                            <div v-for="(phone, index) in phones" :key="'phone_'+index" class="client-phone" :disabled="userBlock">
                                <div>
                                    <div :disabled="userBlock" @click="phone.show = true"  v-if="!phone.show" class="client_text">
                                        {{formatPhoneNumber(phone.phone)}}
                                        <span>{{phone.field && phone.field !== 'null' ? phone.field : ''}}</span>
                                    </div>
                                    <v-row v-else>
                                        <v-col cols="12" sm="8" >
                                            <ValidationProvider ref="phone.phone" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                                <v-text-field v-model="phone.phone" type="tel"
                                                              autofocus
                                                              :error="!valid" :error-messages="errors"
                                                              :disabled="userBlock || loading"
                                                              :label="$t('phone')"
                                                              hide-details
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="phone.field" type="text"
                                                          append-outer-icon="mdi-content-save-edit-outline"
                                                          @click:append-outer="changeArray('phones',phone)"
                                                          :disabled="userBlock || loading"
                                                          :label="$t('additional_data')"
                                                          hide-details
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                          clearable>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" class="pa-0">
                    <v-card style="margin-top: 15px">
                        <v-card-title>
                            <div class="participants mr-auto">{{ $t('emails') }}</div>
                            <v-icon
                                size="18"
                                class="add_icon"
                                color="secondary"
                                @click="addEmail"
                                :disabled="!editable"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-card-title>
                        <v-card-text v-if="emails && emails.length">
                            <div v-for="(email, index) in emails" :key="'email_'+index" class="client_text py-3">
                                <div>
                                    <div v-if="!email.show">
                                    <span  @click="email.show = true" >
                                         {{ (email.email === '' || email.email === 'null' || email.email === null) ? $t('email') : email.email}}
                                    </span>
                                    <v-icon
                                        v-if="can(['client_module'])"
                                        size="18"
                                        class="add_icon"
                                        color="secondary"
                                        @click="sentInvite(email.email)"
                                        :disabled="userBlock"
                                    >
                                        mdi-email
                                    </v-icon>
                                </div>
                                    <v-row v-else>
                                        <v-col cols="12" >
                                            <ValidationProvider ref="email.email" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                                <v-text-field v-model="email.email" type="tel"
                                                              autofocus
                                                              @focusout="changeArray('emails',email)"
                                                              :error="!valid" :error-messages="errors"
                                                              :disabled="userBlock || loading"
                                                              :label="$t('email')"
                                                              hide-details
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                              >
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" class="pa-0" style="margin-top: 15px;">
                    <v-card>
                        <v-card-title>
                            <div class="participants mr-auto">{{ $t('sites') }}</div>
                            <v-icon
                                size="18"
                                class="add_icon"
                                color="secondary"
                                @click="addSite"
                                :disabled="!editable"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-card-title>
                        <v-card-text>
                            <template v-if="sites && sites.length">
                                <div v-for="(site, index) in sites" :key="'site_' + index" class="client_text py-3">
                                    <div>
                                        <div v-if="!site.show" @click="editSite(index)">
                                            {{ (site.url === '' || site.url === 'null' || site.url === null) ? $t('site') : site.url}}
                                        </div>
                                        <v-row v-else>
                                            <v-col cols="12" >
                                                <ValidationProvider ref="site.url" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                                    <v-text-field
                                                        v-model="site.url"
                                                        autofocus
                                                        append-outer-icon="mdi-content-save-edit-outline"
                                                        @click:append-outer="changeSites(index, site)"
                                                        @focusout="changeSites(index, site)"
                                                        :error="!valid"
                                                        :error-messages="errors"
                                                        :disabled="userBlock || loading"
                                                        :label="$t('site')"
                                                        hide-details
                                                        solo
                                                        flat
                                                        dense
                                                        color="primary"
                                                        class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                        clearable
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div @click="addSite">
                                    Нет сайтов
                                </div>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" class="pa-0">
                    <v-card class="side-block" style="margin-top: 15px">
                        <v-card-title>
                            <div class="participants mr-auto">{{ $t('addresses') }}</div>
                            <v-icon
                                size="18"
                                class="add_icon"
                                color="secondary"
                                @click="addAddress"
                                :disabled="!editable"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-card-title>
                        <v-card-text v-if="addresses && addresses.length" class="pt-0">
                            <div v-for="address in addresses" :key="'address_'+address.address" class="client_text py-3">
                                <div>
                                    <div @click="address.show = true" v-if="!address.show">
                                        {{address.address}}
                                        <span>{{address.office && address.office !== 'null' ? address.office : ''}}</span>
                                    </div>
                                    <v-row v-else>
                                        <v-col cols="12" sm="8">
                                            <ValidationProvider ref="address.address" rules="required|min:1|max:255"
                                                                v-slot="{ errors, valid }">
                                                <v-text-field v-model="address.address" type="tel"
                                                              autofocus
                                                              :error="!valid" :error-messages="errors"
                                                              :disabled="userBlock || loading"
                                                              :label="$t('address')"
                                                              hide-details
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field v-model="address.office" type="text"
                                                          append-outer-icon="mdi-content-save-edit-outline"
                                                          @click:append-outer="changeArray('addresses',address)"
                                                          :disabled="userBlock || loading"
                                                          :label="$t('office')"
                                                          hide-details
                                                          solo
                                                          flat
                                                          dense
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                          clearable>
                                            </v-text-field>
                                        </v-col>

                                    </v-row>
                              </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" class="pa-0">
                    <v-card class="side-block" style="margin-top: 15px">
                        <v-card-title>
                            <div class="participants">{{ $t('additional_information') }}</div>
                        </v-card-title>
                        <v-card-text>
                            <div class="text-info" v-if="type_uved === 1">
                                <div class="text-info__label">{{$t('company_name')}}</div>
                                <div class="text-info_-value" :class="show_company_name ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('company_name')"  v-if="!show_company_name">
                                        <span v-if="company_name && company_name !== 'null'">{{ company_name }}</span>
                                        <span v-else class="client_no_text">{{$t('enter_text')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="company_name" rules="min:1|max:255" v-slot="{ errors, valid }">
                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('company_name')"

                                                v-model="company_name" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"

                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info" v-if="type_uved === 2">
                                <div class="text-info__label">{{$t('first_name')}}</div>
                                <div class="text-info__value" :class="show_first_name ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('first_name')"  v-if="!show_first_name">
                                        <span v-if="first_name && first_name !== 'null'">{{ first_name }}</span>
                                        <span v-else class="client_no_text">{{$t('enter_text')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="first_name" rules="min:1|max:255"
                                                            v-slot="{ errors, valid }">

                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('first_name')"
                                                v-model="first_name" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info" v-if="type_uved === 2">
                                <div class="text-info__label">{{$t('last_name')}}</div>
                                <div class="text-info__value" :class="show_last_name ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('last_name')"  v-if="!show_last_name">
                                        <span v-if="last_name && last_name !== 'null'">{{ last_name }}</span>
                                        <span v-else class="client_no_text">{{$t('enter_text')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="last_name" rules="min:1|max:255"
                                                            v-slot="{ errors, valid }">

                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('last_name')"
                                                v-model="last_name" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info" v-if="type_uved === 2">
                                <div class="text-info__label" :class="show_middle_name ? 'pt_3 pb-0': ''">{{$t('middle_name')}}</div>
                                <div class="text-info__value">
                                    <div @click="showInput('middle_name')"  v-if="!show_middle_name">
                                        <span v-if="middle_name && middle_name !== 'null'">{{ middle_name }}</span>
                                        <span v-else class="client_no_text">{{$t('enter_text')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="middle_name" rules="min:1|max:255"
                                                            v-slot="{ errors, valid }">

                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('middle_name')"
                                                v-model="middle_name" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div v-if="type_uved === 2" class="text-info">
                                <div class="text-info__label">{{$t('sex')}}</div>
                                <div class="text-info__value" :class="show_sex ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('sex')"  v-if="!show_sex">
                                        <span v-if="sex && sex.name">{{ sex.name }}</span>
                                        <span v-else class="client_no_text">{{$t('select_value')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="sex" rules="required" v-slot="{ errors, valid }">
                                            <v-select v-model="sex" :disabled="loading" :items="sexItems"
                                                      autofocus
                                                      @change="changeField('sex')"
                                                      @focusout="changeField('sex')"
                                                      :error="!valid" :error-messages="errors"
                                                      item-text="name" item-value="id"
                                                      return-object
                                                      :label="$t('sex')"
                                                      hide-details
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                      ></v-select>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info">
                                <div class="text-info__label">{{$t('bin_inn')}}</div>
                                <div class="text-info__value" :class="show_bin_inn ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('bin_inn')"  v-if="!show_bin_inn">
                                        <span v-if="bin_inn && bin_inn !== 'null'">{{ bin_inn }}</span>
                                        <span v-else class="client_no_text">{{$t('enter_text')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="bin_inn" rules="min:1|max:255" v-slot="{ errors, valid }">
                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('bin_inn')"
                                                v-model="bin_inn" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info">
                                <div class="text-info__label">{{$t('type_activity')}}</div>
                                <div class="text-info__value" :class="show_type_activity ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('type_activity')"  v-if="!show_type_activity">
                                        <span v-if="type_activity && type_activity.name">{{ type_activity.name }}</span>
                                        <span v-else class="client_no_text">{{$t('select_value')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="type_activity" rules="required" v-slot="{ errors, valid }">
                                            <v-select v-model="type_activity" :disabled="loading" :items="type_activityItems"
                                                      autofocus
                                                      @change="changeField('type_activity')"
                                                      @focusout="changeField('type_activity')"
                                                      :error="!valid" :error-messages="errors"
                                                      item-text="name" item-value="id"
                                                      return-object
                                                      :label="$t('type_activity')"
                                                      hide-details
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                      ></v-select>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info">
                                <div class="text-info__title">{{$t('foundation_day')}}</div>
                                <div class="text-info_-value">
                                    <span v-if="foundation_day && foundation_day !== 'null'">{{ foundation_day }}</span>
                                    <span v-else class="client_no_text">{{$t('select_value')}}</span>
                                </div>
                            </div>

                            <div class="text-info">
                                <div class="text-info__label">{{$t('attraction_channel')}}</div>
                                <div class="text-info__value" :class="show_attraction_channel ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('attraction_channel')"  v-if="!show_attraction_channel">
                                        <span v-if="attraction_channel && attraction_channel.name">{{ attraction_channel.name }}</span>
                                        <span v-else class="client_no_text">{{$t('select_value')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="attraction_channel" rules="required" v-slot="{ errors, valid }">
                                            <v-select v-model="attraction_channel" :disabled="loading" :items="attraction_channelItems"
                                                      autofocus
                                                      @change="changeField('attraction_channel')"
                                                      @focusout="changeField('attraction_channel')"
                                                      :error="!valid" :error-messages="errors"
                                                      item-text="name" item-value="id"
                                                      return-object
                                                      :label="$t('attraction_channel')"
                                                      hide-details
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                      ></v-select>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info">
                                <div class="text-info__label">{{$t('supplier')}}</div>
                                <div class="text-info__value" :class="show_supplier ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('supplier')"  v-if="!show_supplier">
                                        <span v-if="supplier && supplier !== 'null'">{{ supplier }}</span>
                                        <span v-else class="client_no_text">{{$t('enter_text')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="supplier" rules="min:1|max:255" v-slot="{ errors, valid }">
                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('supplier')"
                                                v-model="supplier" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info">
                                <div class="text-info__label">{{$t('products')}}</div>
                                <div class="text-info__value" :class="show_products ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('products')"  v-if="!show_products">
                                        <span v-if="products && products !== 'null'">{{ products }}</span>
                                        <span v-else class="client_no_text">{{$t('enter_text')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="products" rules="min:1|max:255"
                                                            v-slot="{ errors, valid }">

                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('products')"
                                                v-model="products" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info">
                                <div class="text-info__label">{{$t('client_code')}}</div>
                                <div class="text-info__value" :class="show_client_code ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('client_code')"  v-if="!show_client_code">
                                        <span v-if="client_code && client_code !== 'null'">{{ client_code }}</span>
                                        <span v-else class="client_no_text">{{$t('enter_text')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="client_code" rules="min:1|max:255"
                                                            v-slot="{ errors, valid }">

                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('client_code')"
                                                v-model="client_code" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info">
                                <div class="text-info__label">{{$t('supplier_address')}}</div>
                                <div class="text-info__value" :class="show_supplier_address ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('supplier_address')"  v-if="!show_supplier_address">
                                        <span v-if="supplier_address && supplier_address !== 'null'">{{ supplier_address }}</span>
                                        <span v-else class="client_no_text">{{$t('enter_text')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="supplier_address" rules="min:1|max:255"
                                                            v-slot="{ errors, valid }">

                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('supplier_address')"
                                                v-model="supplier_address" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info">
                                <div class="text-info__label">{{$t('type_transport')}}</div>
                                <div class="text-info__value" :class="show_type_transport ? 'pt_3 pb-0': ''">

                                    <div @click="showInput('type_transport')"  v-if="!show_type_transport">
                                        <span v-if="type_transport && type_transport.name">{{ type_transport.name }}</span>
                                        <span v-else class="client_no_text">{{$t('select_value')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="type_transport" rules="min:1" v-slot="{ errors, valid }">
                                            <v-select v-model="type_transport" :disabled="loading" :items="type_transportItems"
                                                      autofocus
                                                      @change="changeField('type_transport')"
                                                      @focusout="changeField('type_transport')"
                                                      :error="!valid" :error-messages="errors"
                                                      item-text="name" item-value="id"
                                                      return-object
                                                      :label="$t('type_transport')"
                                                      hide-details
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                      ></v-select>

                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="text-info">
                                <div class="text-info__label">{{$t('company')}}</div>
                                <div class="text-info__value" :class="show_company ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('company')"  v-if="!show_company">
                                        <span v-if="companyItems[0] && companyItems[0].name">{{ companyItems[0].name }}</span>
                                        <span v-else class="client_no_text">{{$t('select_value')}}</span>

                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="company" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="company" :items="companyItems"
                                                            autofocus
                                                            @change="changeField('company')"
                                                            @focusout="changeField('company')"
                                                            :error-messages="errors" :error="!valid"
                                                            :search-input.sync="companySearching"
                                                            item-text="name" item-value="id"
                                                            :loading="loadingCompanies" :disabled="loading"
                                                            :no-data-text="companySearching ? $t('nothing_found_by',{'search': companySearching}) : $t('nothing_found_name')"
                                                            :label="$t('company')"
                                                            @click:clear="companyItems=[]"
                                                            @click="clearCompanies"
                                                            autocomplete="off"
                                                            hide-details
                                                            solo
                                                            flat
                                                            dense
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                            clearable>
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.name"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.bin_iin"></span>
                                        </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.name"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.bin_iin"></span>
                                        </span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="text-info">
                                <div class="text-info__label">{{$t('tnved')}}</div>
                                <div class="text-info__value" :class="show_tnved ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('tnved')"  v-if="!show_tnved">
                                        <span v-if="tnved && tnved !== 'null'">{{ tnved }}</span>
                                        <span v-else class="client_no_text">{{$t('enter_text')}}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="tnved" rules="min:1|max:255" v-slot="{ errors, valid }">
                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('tnved')"
                                                v-model="tnved" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                :label="$t('enter_text')"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                color="primary"
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <UvedBanks :uuid="uved_uuid" :editable="editable"/>

                <v-col cols="12" class="pa-0">
                    <v-card style="margin-top: 15px">
                        <v-card-title class="d-flex">
                            <div class="participants mr-auto">{{ $t('participants') }}</div>
                            <div class="">
                                <v-icon
                                    size="18"
                                    class="add_icon"
                                    color="secondary"
                                    :disabled="!editable"
                                    @click="dialogAddParticipants=true"
                                >
                                    mdi-plus-circle-outline
                                </v-icon>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <div v-if="!$vuetify.breakpoint.xlOnly && ( show_responsible && show_responsible.length > 0)">{{$t('responsible')}}</div>
                            <v-list class="my-0 py-0">
                                <template>
                                    <div v-if="$vuetify.breakpoint.xlOnly  && (show_responsible && show_responsible.length > 0)" class="my-0 pl-4 sub_participants" v-text="$t('responsible')"></div>
                                    <v-list-item class="px-0" v-for="responsible in show_responsible" :key="responsible.name">
                                        <v-list-item-avatar :class="{'border_blue': responsible.photo === '/img/avatar.png'}" size="36" class="my-0 py-0">
                                            <img v-if="responsible.photo !== '/img/avatar.png'" :src="responsible.photo" :alt="responsible.name">
                                            <v-icon v-else color="primary" size="22">
                                                ${{'settingsIcon'}}
                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-0 title_subtitle">
                                            <v-list-item-title v-text="responsible.name"></v-list-item-title>
                                            <v-list-item-subtitle v-text="formatPhoneNumber(responsible.phone)"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" class="pa-0">
                    <v-card class="side-block" style="margin-top: 15px">
                        <v-card-title class="d-flex">
                            <div class="participants mr-auto">{{ $t('files') }} {{accompanying_document_urls.length > 0 ? ': '+accompanying_document_urls.length : ''}}</div>
                            <div>
                                <v-icon
                                    size="18"
                                    class="add_icon"
                                    :disabled="!editable"
                                    color="secondary"
                                    @click="document.getElementById('accompanying_documents').click()"
                                >
                                    mdi-plus-circle-outline
                                </v-icon>
                            </div>
                        </v-card-title>
                        <v-card-text class="mt-0 pt-0" v-if="accompanying_document_urls.length > 0">
                            <v-list>
                                <v-list-item outlined class="file_item" v-for="file in accompanying_document_urls" :key="file.id + '_' + Date.now()">
                                    <v-list-item-icon>
                                        <v-img
                                            max-width="45"
                                            :alt="`${file.name}`"
                                            :src="file.url"
                                            max-height="30"
                                        ></v-img>
                                        <v-icon
                                            x-large
                                            size="10"
                                            color="primary"
                                            v-if="!mimeTypeImage(file.type)">mdi-file-document-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="file_text">
                                        <v-list-item-title v-text="file.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="(file.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-text="file.author.name"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon class="d-flex align-self-center mt-3 px-0 mx-0">
                                        <v-icon color="primary" class="mr-2" :title="$t('download')" @click="downloadFile(file)">mdi-cloud-upload</v-icon>
                                        <v-icon :disabled="userBlock" color="primary" v-if="file.uuid" :title="$t('delete')" @click="deleteFileServer(file)">mdi-delete-outline</v-icon>
                                        <v-icon :disabled="userBlock" color="primary" v-if="!file.uuid" :title="$t('delete')" @click="deleteAccompanyingDocumentFile(file)">mdi-delete-outline</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col class="py-0" cols="12">
                    <!--accept=".pdf,image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
                    <v-file-input
                        hide-input prepend-icon=""
                        :label="$t('accompanying_documents')"
                        v-model="accompanying_documents"
                        id="accompanying_documents"
                        multiple
                        @change="accompanyingDocumentsPreviewFiles"
                    >
                    </v-file-input>
                </v-col>
            </template>
        </PageLayout>
    </ValidationObserver>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import debounce from "lodash/debounce";
    import CalendarCase from "../components/CalendarCase.vue";
    import {loadYmap, yandexMap} from 'vue-yandex-maps'
    import AvWaveform from "vue-audio-visual/src/components/AvWaveform";
    import UvedBanks from "@/components/Uved/Banks.vue";
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import FormRight from '@/components/Eav/FormRight.vue';
    // import FormTab from '@/components/Eav/FormTab.vue';
    import FormTabField from '@/components/Eav/FormTabField.vue';
    import UserSelection from "@/components/Form/UserSelection.vue";
    import ResponsiveTabs from "@/components/Leentech/ResponsiveTabs.vue";
    import ResponsiveTab from "@/components/Leentech/ResponsiveTab.vue";
    import CommentInput from "@/components/CommentInput.vue";
    import Users from "@/components/Users.vue";

    export default {
        name: 'UvedShow',
        components: {
            Users,
            CommentInput,
            UserSelection,
            ValidationProvider,
            ValidationObserver,
            CalendarCase,
            yandexMap,
            AvWaveform,
            UvedBanks,
            PageLayout,
            FormRight,
            FormTabField,
            ResponsiveTabs,
            ResponsiveTab
        },
        inject: ['forceRerender'],
        data() {
            return {
                forms: [],
                tabs: [],
                heading: null,
                author: {},
                progress: 0,
                loading: false,
                tab: 0,
                id: null,
                sexItems: [],
                type_activityItems: [],
                attraction_channelItems: [],
                favorite: false,
                uved_status: null,
                company: null,
                companyItems: [],
                companySearching: null,
                loadingCompanies: false,
                uved_type: null,
                uvedTypeItems: [],
                uvedTypeSearching: null,
                loadingUvedTypes: false,
                text_uved: "",
                text_uved_edit: false,
                text: null,
                show_responsible: [],
                responsible: null,
                responsibleItems: [],
                responsibleSearching: null,
                loadingResponsibles: false,
                auditor: null,
                auditorItems: [],
                aligner: null,
                alignerItems: [],
                auditorSearching: null,
                loadingAuditors: false,
                dragover: false,
                document,
                documents:[],
                files:[],
                document_urls: [],
                dialog_file: false,
                file_urls: [],
                loadingUved: true,
                loadingMessage: false,
                loadingSex: false,
                loadingTypeActivity: false,
                loadingTypeTransport: false,
                loadingAttractionChannel: false,
                loadingInvoice: true,
                loadingTask: true,
                loadingTopic: false,
                uved_uuid: null,
                request: [],
                answer: [],
                type_uved: null,
                uved_name: null,
                company_name: null,
                first_name: '',
                last_name: '',
                middle_name: '',
                bin_inn: null,
                phones: [],
                emails: [],
                sites: [],
                addresses: [],
                sex: {},
                type_activity: {},
                foundation_day: null,
                attraction_channel: {},
                supplier: null,
                products: null,
                client_code: null,
                supplier_address: null,
                tnved: null,
                show_company_name: false,
                show_first_name: false,
                show_last_name: false,
                show_middle_name: false,
                show_bin_inn: false,
                show_sex: false,
                show_type_activity: false,
                show_foundation_day: false,
                show_attraction_channel: false,
                show_supplier: false,
                show_products: false,
                show_client_code: false,
                show_supplier_address: false,
                show_type_transport: false,
                show_tnved: false,
                show_company: false,
                chatMessages: [],
                chatMessagesDate: [],
                toolbar_reply:false,
                uved_active: true,
                reply_msg: {},
                reply_name: null,
                reply_uuid: null,
                message_menu: [
                    { title: this.$t('delete'), click: "delete"},
                    { title: this.$t('reply'), click: "reply" },
                ],
                created_at: null,
                accompanying_documents: [],
                accompanying_document_urls: [],
                accompanying_documents_preview: [],
                accompanying_documents_dialog: false,
                not_accepted: 0,
                accepted: 0,
                rejected: 0,
                is_aligner: null,
                is_responsible: null,
                is_agreed: false,
                dialogStatusApproval: false,
                reject_text: null,
                type_transport: null,
                type_transportItems: [],
                totalInvoices: 0,
                invoiceItems: [],
                headersInvoice: [
                    {
                        text: this.$t('amount'),
                        align: "left",
                        sortable: true,
                        value: "download"
                    },
                    {
                        text: this.$t('amount'),
                        align: "left",
                        sortable: true,
                        value: "amount"
                    },
                    {
                        text: this.$t('number_deal'),
                        align: "left",
                        sortable: true,
                        value: "number_deal"
                    },
                    {
                        text: this.$t('invoice_status'),
                        align: "left",
                        sortable: true,
                        value: "invoice_status"
                    },
                    {
                        text: this.$t('date'),
                        align: "left",
                        sortable: true,
                        value: "date"
                    },
                    {
                        text: this.$t('company'),
                        align: "left",
                        sortable: true,
                        value: "company.name"
                    },
                    {
                        text: this.$t('manager'),
                        align: "left",
                        sortable: true,
                        value: "admin.name"
                    }
                ],
                tasksItems: [],
                totalTasks: 0,
                headersTask: [
                    {
                        text: this.$t('task_name'),
                        align: "left",
                        sortable: true,
                        value: "title"
                    },
                    {
                        text: this.$t('task_status'),
                        sortable: false,
                        value: "task_status"
                    },
                    {
                        text: this.$t('deadline'),
                        sortable: true,
                        value: "deadline"
                    },
                    {
                        text: this.$t('date'),
                        sortable: true,
                        value: "created_at"
                    },
                    {
                        text: this.$t('author'),
                        align: "left",
                        sortable: false,
                        value: "admin.name"
                    },
                ],
                totalDeals:0,
                dealItems: [],
                headersDeal: [
                    {
                        text: this.$t('number_deal'),
                        align: "left",
                        sortable: true,
                        value: "number"
                    },
                    {
                        text: this.$t('deal_status'),
                        sortable: false,
                        value: "deal_status"
                    },
                    {
                        text: this.$t('date'),
                        sortable: true,
                        value: "created_at"
                    },
                    {
                        text: this.$t('author'),
                        align: "left",
                        sortable: false,
                        value: "admin"
                    },
                    {
                        text: this.$t('active'),
                        align: "center",
                        sortable: true,
                        value: "active"
                    },
                ],
                selected: [],
                topicItems: [],
                loadingDealOrders:true,
                display_deal: false,
                display_task: false,
                display_invoice: false,
                display_case: false,
                display_tracking: false,
                display_chat: false,
                company_top: {},
                itemYN: [
                    {
                        id: 0,
                        name: this.$t('no'),
                    },
                    {
                        id: 1,
                        name: this.$t('yes'),
                    }
                ],
                invoice_status_edit: null,
                invoiceStatusEditItems: [],
                dialogInvoiceStatus:false,
                loadingTracking:true,
                trackings: [],
                data_info: [
                    {
                        "type": "data",
                        "name" : this.$t('data'),
                        "class": "mr-2",
                        "color": "primary",
                    },
                    {
                        "type": "tracking",
                        "name": this.$t('tracking'),
                        "class": "border_0",
                        "color": "color_B3B3B3"
                    }
                ],
                info_type: "data",
                tracking_geo: [],
                referencePoints: [],
                showAllMarkers: true,
                mapMarker: null,
                zoom: 12,
                ymaps: null,
                coordinates: [],
                showMap: false,
                coordinatesPolygon: [],
                dialogDetailTracking: false,
                detail_tracking: {},
                statusItems: [],
                type_client: null,
                typeClientItems: [],
                dialogAddParticipants: false,
                dialog_image_view:false,
                image_view: {},
                show_title: false,
                dialogLinkTask: false,
                taskItems: [],
                task: null,
                taskAddItems: [],
                taskSearching: null,
                loadingTasks: false,
                perPage: 50,
                permissions: [],
                uved_id: null,
                edit_type_client: null
            }
        },
        computed: {
            ...mapGetters(['userBlock','language','listLanguages', 'lang', 'timezone']),

            editable() {
                return this.permissions.includes('uved.edit') && !this.userBlock
            },

            coords: {
                get: function () {
                    return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [51, 71]
                },
                set: function (val) {
                    this.coordinates = (val && val.length === 2) ? val : [51, 71]
                }
            },
            mapSettings() {
                this.$yandex.lang = 'ru_RU'
                return this.$yandex
            },
            languages() {
                return this.listLanguages
            },
            tinymceInit() {
                return this.tinymceInitDefault()
            },

        },
        async mounted() {
            await this.getTypeClients()
            await this.checkCreate()
            await this.getTaps()
            await this.getGroups()
            await this.getMessages()
            await this.getSexes()
            await this.getTypeActivities()
            await this.getTypeTransports()
            await this.getAttractionChannels()
            await this.getInvoices()
            await this.getTasks()
            await this.getDeals()
            await this.getTopic()
            await this.getInvoiceStatuses()
            await this.getStatus()
            await this.getTracking()
            await this.UvedMessageslWebsocket()
            if (this.$refs.calendarCase) {
                this.getCalendarCase();
            }
        },
        watch: {
            companySearching: debounce(function (val) {
                if (val && !this.company) {
                    this.getCompanies(val)
                }
            }, 500),
            responsibleSearching: debounce(function (val) {
                if (val) {
                    this.getResponsibles(val)
                }
            }, 500),
            auditorSearching: debounce(function (val) {
                if (val) {
                    this.getAuditors(val)
                }
            }, 500),
            uvedTypeSearching: debounce(function (val) {
                if (val) {
                    this.getUvedTypes(val)
                }
            }, 500),
            taskSearching: debounce(function (val) {
                if (val) {
                    this.getAddTasks(val)
                }
            }, 500),
        },
        updated(){


        },
        methods: {
            showTab(item){
                this.display_open('close_all')
                this.tabs.forEach(tab => {
                    if (tab.name === item.name && tab.tab_id === item.tab_id) {
                        tab.show = true;
                    } else {
                        tab.show = false;
                    }
                });
            },
            async getTaps(){

                this.loading = true;
                let params = {};

                params.type_form = 'tabs';
                params.entity_type = 'uved';
                params.entity_uuid = this.$route.params.id;
                params.sub_entity_type = 'type_client';
                params.sub_entity_id = this.edit_type_client;

                await this.$http
                    .get(`admin/dynamic_form`, {
                        params: params,
                    })
                    .then(res => {
                        if (res?.body?.data?.[0]?.id) {
                            this.tabs = res.body.data;
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_list_tabs'))
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },
            async getGroups(){

                this.loading = true;
                let params = {};

                params.type_form = 'groups';
                params.entity_type = 'uved';
                params.entity_uuid = this.$route.params.id;
                params.sub_entity_type = 'type_client';
                params.sub_entity_id = this.edit_type_client;

                await this.$http
                    .get(`admin/dynamic_form`, {
                        params: params,
                    })
                    .then(res => {
                        if (res?.body?.data?.[0]?.id) {
                            this.forms = res.body.data;
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_list_groups'))
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },
            async translation(text, comment_uuid = ''){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                formData.append('text', text)
                if(comment_uuid !== ''){
                    formData.append('comment_uuid', comment_uuid)
                }
                if (this.language) {
                    formData.append('language', this.language)
                }
                if (this.$route.params.id) {
                    formData.append('uved_uuid', this.$route.params.id)
                }


                // Save
                await this.$http
                    .post(`admin/translate_text`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        if(res.body.data.comment){
                            let message_date = this.chatMessagesDate[this.$moment(res.body.data.comment.created_at).format('YYYY-MM-DD')];
                            let uuidToFind = res.body.data.comment.uuid;
                            let foundObject = message_date.find(obj => obj.uuid === uuidToFind);
                            if (typeof foundObject != "undefined") {
                                foundObject.comment =  res.body.data.comment.comment;
                                foundObject.uuid = res.body.data.comment.uuid;
                            }
                        }

                        this.$toastr.success(this.$t('translated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('translation_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
            getTranslation(translations) {
                if (!Array.isArray(translations) || translations.length === 0) {
                    translations = [];
                    return null;
                }
                let translationObject = translations.find(obj => obj && Object.prototype.hasOwnProperty.call(obj, this.language));
                return translationObject ? translationObject[this.language] : null;
            },
            async checkCreate() {
                if (this.$route.name === "uved.create") {
                    this.heading = this.$t('uved_creation')
                } else {
                    this.heading = this.$t('uved_editing')
                    if (this.$route.params.id) {
                        await this.getUved()
                    }
                }
            },
            rowClass(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5' : 'rowMobiClass';
            },
            rowClass2(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table7' : 'rowMobiClass';
            },
            rowClass3(){
                return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5' : 'rowMobiClass';
            },
            display_open(type){
                if(type !== 'close_all'){
                    this.tabs.forEach(tab => {
                        tab.show = false;
                    });
                }
                switch (type){
                    case 'display_task':
                        this.display_invoice = false;
                        this.display_chat = false;
                        this.display_case = false;
                        this.display_tracking = false;
                        this.display_deal = false;
                        this.display_task = (this.display_task) ? false : true;
                        break;
                    case 'display_invoice':
                        this.display_invoice = (this.display_invoice) ? false :true;
                        this.display_task = false;
                        this.display_case = false;
                        this.display_deal = false;
                        this.display_chat = false;
                        this.display_tracking = false;
                        break;
                    case 'display_case':
                        this.display_case = (this.display_case) ? false :true;
                        this.display_task = false;
                        this.display_invoice = false;
                        this.display_deal = false;
                        this.display_chat = false;
                        this.display_tracking = false;
                        break;
                    case 'display_tracking':
                        this.display_tracking = (this.display_tracking) ? false :true;
                        this.display_task = false;
                        this.display_invoice = false;
                        this.display_deal = false;
                        this.display_chat = false;
                        this.display_case = false;
                        break;
                    case 'display_deal':
                        this.display_deal = (this.display_deal) ? false :true;
                        this.display_task = false;
                        this.display_invoice = false;
                        this.display_chat = false;
                        this.display_tracking = false;
                        this.display_case = false;
                        break;
                    case 'display_chat':
                        this.display_chat = (this.display_chat) ? false :true;
                        this.display_task = false;
                        this.display_invoice = false;
                        this.display_deal = false;
                        this.display_tracking = false;
                        this.display_case = false;
                        break;
                }
            },
            addPhone(){
                this.phones.push({'phone':'+','field':'','show': 1})
            },
            addEmail(){
                this.emails.push({'email':'','show': 1})
            },
            addSite(){
                this.sites.push({'url':'','comment': '', 'show': 1});
            },
            editSite(index){
                this.$set(this.sites[index], 'show', true);
            },
            addAddress(){
                this.addresses.push({'address':'','office':'','show': 1})
            },
            closeInvoiceStatus(){
                this.dialogInvoiceStatus  = false
                this.invoice_status_edit = null
                this.invoice_id = null
            },
            openDialogInvoiceStatus(item){
                this.invoice_id = item.id
                this.invoice_status_edit = item.invoice_status_id
                this.dialogInvoiceStatus  = true
            },
            async editParticipants(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (this.$route.params.id ) {
                    formData.append('uved_uuid', this.$route.params.id)
                }

                if (this.responsible && this.responsible.length > 0) {
                    for (let i in this.responsible) {
                        if (this.responsible[i].id !== undefined && this.responsible[i].id > 0) {
                            formData.append(`responsibles[${i}]`, this.responsible[i].id)
                        }
                    }
                }

                if (this.company) {
                    if (this.company.id) {
                        formData.append('company', this.company.id)
                    } else {
                        formData.append('company', this.company)
                    }
                }


                // Add
                await this.$http
                    .put('admin/uved_participant', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.dialogAddParticipants = false;
                        this.show_responsible = this.responsible;
                        this.$toastr.success(this.$t('uved_has_been_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('uved_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            async getInvoiceStatuses() {
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`admin/invoice_status`, {
                        params: params,
                    })
                    .then(res => {
                        this.invoice_statusItems = res.body.data
                        this.invoiceStatusEditItems = res.body.data
                        this.system_filters = res.body.data
                    })
                    .catch(err => {
                        this.invoice_statusItems = [];
                        this.invoiceStatusEditItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_invoice_statuses'))
                    });
            },
            async paymentConfirmation() {

                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (this.invoice_id) {
                    formData.append('invoice_id', this.invoice_id)
                }
                if (this.invoice_status_edit) {
                    formData.append('invoice_status_edit', this.invoice_status_edit)
                }


                await this.$http
                    .put(`admin/invoice/${this.invoice_id}/status`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('invoice_has_been_updated'))
                        this.closeInvoiceStatus();
                        this.getInvoices();
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('invoice_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        if(prop ==='answer_scanned' || prop ==='request_scanned'){
                                            this.$toastr.error(err.body.errors[prop][0])
                                        }
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            editInvoice(item) {
                this.$router.push({
                    name: 'invoice.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async getInvoices() {
                let params = {};
                params.sortBy = 'id';
                params.sortDir =  'desc';
                params.page = 1
                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }
                if(params.company){
                    this.loadingInvoice = true;
                    await this.$http
                        .get("admin/invoice", {
                            params: params,
                        })
                        .then(res => {
                            this.invoiceItems = res.body.data
                            this.totalInvoices = res.body.meta.total
                        })
                        .catch(err => {
                            this.invoiceItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_invoices'))
                        })
                        .finally(end => {
                            this.loadingInvoice = false
                        })
                }
                else{
                    this.loadingInvoice = false
                }
            },

            ImageViewClose(){
                this.dialog_image_view = false;
                this.image_view = {};
            },
            ImageView(msg){
                this.dialog_image_view = true;
                this.image_view = msg;
            },
            downloadFileMessage(url, file_name) {
                fetch(url)
                    .then((resp) => resp.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = file_name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.$toastr.success(this.$t('file_download_started'))
                    })
                    .catch(() =>
                        this.$toastr.error(this.$t('file_download_error'))

                    );
            },

            addDeal() {
                this.$router.push({
                    name: 'deal.create.uved',
                    params: {
                        uved_uuid: this.$route.params.id
                    }
                })
            },
            addTask() {
                this.$router.push({
                    name: 'task.create.uved',
                    params: {
                        uved_uuid: this.$route.params.id
                    }
                })
            },
              linkTask(){
                this.dialogLinkTask = true;
            },
            clearTasks() {
                if (!this.task) {
                    this.taskAddItems = []
                }
            },
            clearEmptyTaskItems(items) {
                if (items && items.length > 0) {
                    this.task = items.filter(item => typeof item === 'object')
                }
            },
            async getAddTasks(str) {
                if (str) {
                    this.loadingTasks = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.task = str

                    }
                    params.where_null = 'uved_id'
                    params.task_status = -2
                    await this.$http
                        .get("admin/task", {
                            params: params,
                        })
                        .then(res => {
                            this.taskAddItems = res.body.data
                        })
                        .catch(err => {
                            this.taskAddItems = []
                        })
                        .finally(end => {
                            this.loadingTasks = false
                        })
                }
            },

            async addUvedToTask(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()


                if (this.task && this.task.length > 0) {
                    for (let i in this.task) {
                        if (this.task[i].id !== undefined && this.task[i].id > 0) {
                            formData.append(`tasks[${i}]`, this.task[i].id)
                        }
                    }
                }

                if (this.$route.params.id ) {
                    formData.append('uved_uuid', this.$route.params.id)
                }
                // Add
                await this.$http
                    .post('admin/uved_task', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('uved_has_been_updated'))
                        this.dialogLinkTask = false;
                        this.getTasks();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('ubed_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },

            async getTasks() {
                let params = {};
                params.sortBy = 'created_at';
                params.sortDir =  'desc';
                params.perPage =  this.perPage;
                params.page = 1
                params.task_status = -2
                if(this.uved_uuid) {
                    this.loadingTask = true;
                    params.uved_uuid = this.uved_uuid
                    await this.$http
                        .get("admin/task", {
                            params: params,
                        })
                        .then(res => {
                            this.tasksItems = res.body.data
                            this.totalTasks = res.body.meta.total
                        })
                        .catch(err => {
                            this.tasksItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_tasks'))
                        })
                        .finally(end => {
                            this.loadingTask = false
                        })
                }
            },
            showTask(item) {
                this.$router.push({
                    name: 'task.show',
                    params: {
                        id: item.uuid
                    },
                })
                this.forceRerender()
            },
            showDeal(uuid) {
                this.$router.push({
                    name: 'deal.show',
                    params: {
                        id: uuid
                    }
                })
            },
            async getDeals() {
                let params = {};
                params.sortBy = 'created_at';
                params.sortDir =  'desc';
                params.perPage =  this.perPage;
                params.page = 1
                params.deal_status = -2
                if(this.uved_uuid) {
                    this.loadingDealOrders = true;
                    params.uved_uuid = this.uved_uuid
                    await this.$http
                        .get("admin/deal", {
                            params: params,
                        })
                        .then(res => {
                            this.dealItems = res.body.data
                            this.totalDeals = res.body.meta.total
                        })
                        .catch(err => {
                            this.dealItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_deals'))
                        })
                        .finally(end => {
                            this.loadingDealOrders = false
                        })
                }
            },

            async getStatus() {
                var _this = this;
                this.loadingSataus = true;

                let params = {};
                if (this.language) {
                    params.language = this.language
                }



                await this.$http
                    .get(`admin/status`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        this.statusItems = res.body.data

                    })
                    .catch(err => {
                        this.statusItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_statuses'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loadingSataus = false
                    })
            },
            async getTracking() {
                var _this = this;
                this.progress = 0
                this.loadingTracking = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }
                if(params.company) {
                    await this.$http
                        .get(`admin/tracking`, {
                            params: params,
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.trackings = res.body.data

                        })
                        .catch(err => {
                            this.trackings = [];
                            this.$toastr.error(this.$t('failed_to_get_list_trackings'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loadingTracking = false
                        })
                }
            },
            showDetailsApplication(item){
                this.detail_tracking = {};
                this.detail_tracking = item;
                this.dialogDetailTracking = true;

            },
            show_info(type){
                this.info_type = type;
                if(type === 'tracking'){

                    let mclass = this.data_info[0].class;
                    let mcolor = this.data_info[0].color;

                    this.data_info[0].class = this.data_info[1].class
                    this.data_info[0].color = this.data_info[1].color

                    this.data_info[1].class= mclass;
                    this.data_info[1].color = mcolor;

                    this.getTrackingDeal()
                }
                else if(this.data_info[0].color !== 'primary'){
                    let mclass = this.data_info[1].class;
                    let mcolor = this.data_info[1].color;

                    this.data_info[1].class = this.data_info[0].class
                    this.data_info[1].color = this.data_info[0].color

                    this.data_info[0].class= mclass;
                    this.data_info[0].color = mcolor;
                }

            },
            close_info(){
                this.detail_tracking = {};
                this.dialogDetailTracking = false;
            },
            async getTrackingDeal() {
                var _this = this;
                this.loading = true;

                let params = {};


                await this.$http
                    .get("admin/tracking/deal/"+this.detail_tracking.deal_uuid, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.referencePoints = []
                        this.tracking_geo = res.body.data.tracking[0];
                        this.coordinates = this.tracking_geo.from.point

                        this.referencePoints.push(this.tracking_geo.from.point);
                        if(this.tracking_geo && this.tracking_geo.tracking)
                        {
                            for (var i=0; i<this.tracking_geo.tracking.length; i++){
                                this.referencePoints.push(this.tracking_geo.tracking[i].point)
                            }
                        }


                        this.ymapInit()
                    })
                    .catch(err => {
                        this.tracking_geo = []
                        this.$toastr.error(this.$t('failed_to_get_list_tracking'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async ymapInit() {
                var _this = this
                if (window.ymaps) {
                    this.showMap = false
                    if (this.$refs.map && this.$refs.map.myMap) {
                        this.$refs.map.myMap.destroy()
                    }
                    document.getElementById("vue-yandex-maps").remove()
                    delete window.ymaps
                }
                await loadYmap({...this.mapSettings, debug: true})
                if (window.ymaps) {
                    window.ymaps.ready(function () {
                        _this.showMap = true
                    })
                }
            },
            initHandler(e) {
                var _this = this;
                if (this.coords && this.coords.length !== 2 && window.ymaps) {
                    var location = window.ymaps.geolocation.get({
                        provider: 'yandex',
                        mapStateAutoApply: true,
                        autoReverseGeocode: true
                    })
                    location.then(
                        function (result) {
                            //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                            e.geoObjects.add(result.geoObjects)
                        },
                        function (err) {
                            console.log('Error: ' + err)
                        }
                    )
                }
                e.geoObjects.options.set('draggable', true)
                _this.myMap = e;
                var multiRoute = new window.ymaps.multiRouter.MultiRoute({
                    // Точки маршрута. Точки могут быть заданы как координатами, так и адресом.
                    referencePoints: this.referencePoints,
                }, {
                    // Внешний вид путевых точек.
                    wayPointStartIconColor: "#FFFFFF",
                    wayPointStartIconFillColor: "#B3B3B3",
                    // Внешний вид линии активного маршрута.
                    routeActiveStrokeWidth: 4,
                    routeActiveStrokeStyle: 'solid',
                    routeActiveStrokeColor: "#007EC9",
                    // Внешний вид линий альтернативных маршрутов.
                    routeStrokeStyle: 'dot',
                    routeStrokeWidth: 3,
                    // Автоматически устанавливать границы карты так,
                    // чтобы маршрут был виден целиком.
                    boundsAutoApply: true
                });


                _this.myMap.geoObjects.add(multiRoute);
                //e.geoObjects.add(multiRoute);

                var fullscreenControl = new window.ymaps.control.FullscreenControl();
                _this.myMap.controls.add(fullscreenControl);
                //  e.controls.add('FullscreenControl');
            },

            async getTopic() {
                var _this = this;
                this.loadingTopic = true;
                let params = {};
                if (this.language) {
                    params.language = this.language
                }

                if (this.company) {
                    if (this.company.id) {
                        params.company = this.company.id
                    } else {
                        params.company = this.company
                    }
                }
                params.filter = 'topic';
                if(params.company) {
                    await this.$http
                        .get(`admin/topic`, {
                            params: params,
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.topicItems = res.body.data
                        })
                        .catch(err => {
                            this.topicItems = []
                            this.$toastr.error(this.$t('failed_to_get_list_topics'))
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loadingTopic = false
                        })
                }
            },
            openChat(item){
                this.$router.push({
                    name: 'topic.message',
                    params: {
                        id: item.uuid
                    }
                })
            },

            getCalendarCase(){
                let id_array = 0;
                if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                    id_array = 1
                }
                else{
                    id_array = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1
                }
                this.$refs.calendarCase.getCaseAdmin('uved', id_array);
            },

            handleClick(click,msg, date) {
                switch (click) {
                    case 'delete':
                        this.deleteMessages(msg,date)
                        break;
                    case 'reply' :
                        this.replyOpen(msg)
                        break;
                }
            },
            replyOpen(msg){
                this.reply_uuid = msg.uuid
                this.reply_msg = msg.comment;
                this.reply_name = msg.name;
                this.toolbar_reply = true
            },
            replyClose(){
                this.reply_uuid = null
                this.reply_name = null
                this.toolbar_reply = false
                this.reply_msg = {}
            },

            showInput(field){
                if(this.editable === false){
                    return;
                }
               const show = 'show_'+field;
               if(this.$data[show]){
                   this.$data[show] = false
               }
               else {
                   this.$data[show] = true;


               }
            },

            saveField(field){
                const show = 'show_'+field;
                if(this.$data[show]){
                    this.$data[show] = false
                }
                else {
                    this.$data[show] = true;
                }
                this.saveUvedField(field,this.$data[field]);
            },
            changeField(field){
                const show = 'show_'+field;
                if(this.$data[show]){
                    this.$data[show] = false
                }
                else {
                    this.$data[show] = true;
                }
                if(this.$data[field] && this.$data[field].id){
                    this.saveUvedField(field,this.$data[field].id);
                }
                else {
                    this.saveUvedField(field,this.$data[field]);
                }

            },

            changeArray(field, item){
                const show = 'show_' + field;
                item.show = false;
                this.saveUvedField(field,'');
            },

            changeSites(index, item){
                item.show = false;

                if(!item.url) {
                    this.sites.splice(index, 1);
                }

                this.saveUvedField('sites', '');
            },

            clearCompanies() {
                if (!this.company) {
                    this.companyItems = []
                }
            },

            saveFieldTitle(field){
                const show = 'show_title';
                this.$data[show] = false
                this.saveUvedField(field,this.$data[field]);
                if(field === 'uved_name'){
                    let arr = this.uved_name.split(' ')
                    this.first_name = arr[0] !== undefined ? arr[0] : ''
                    this.last_name = arr[1] !== undefined ? arr[1] : ''
                    this.middle_name = arr[2] !== undefined ? arr[2] : ''
                 }

            },
            redirectToCompany(val) {
                if (val) {
                    this.$router.push({
                        name: 'company.profile',
                        params: {
                            id: val
                        }
                    })
                }
            },
            async getCompanies(str) {
                if (str) {
                    this.loadingCompanies = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.company = str
                    }
                    await this.$http
                        .get("admin/company", {
                            params: params,
                        })
                        .then(res => {
                            this.companyItems = res.body.data
                        })
                        .catch(err => {
                            this.companyItems = []
                        })
                        .finally(end => {
                            this.loadingCompanies = false
                        })
                }
            },
            async getUvedTypes(str) {
                if (str) {
                    this.loadingUvedTypes = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.uved_type = str
                    }
                    await this.$http
                        .get("admin/uved_type", {
                            params: params,
                        })
                        .then(res => {
                            this.uvedTypeItems = res.body.data
                        })
                        .catch(err => {
                            this.uvedTypeItems = []
                        })
                        .finally(end => {
                            this.loadingUvedTypes = false
                        })
                }
            },
            clearUvedTypes() {
                if (!this.uved_type) {
                    this.uvedTypeItems = []
                }
            },


            clearResponsibles() {
                if (!this.responsible) {
                    this.responsibleItems = []
                }
            },
            clearEmptyResponsibleItems(items) {
                if (items && items.length > 0) {
                    this.responsible = items.filter(item => typeof item === 'object')
                }
            },
            async getResponsibles(str) {
                if (str) {
                    this.loadingResponsibles = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.administrator = str
                    }
                    await this.$http
                        .get("admin/admin", {
                            params: params,
                        })
                        .then(res => {
                            this.responsibleItems = res.body.data
                        })
                        .catch(err => {
                            this.responsibleItems = []
                        })
                        .finally(end => {
                            this.loadingResponsibles = false
                        })
                }
            },

            clearAuditors() {
                if (!this.auditor) {
                    this.auditorItems = []
                }
            },
            clearEmptyAuditorItems(items) {
                if (items && items.length > 0) {
                    this.auditor = items.filter(item => typeof item === 'object')
                }
            },
            async getAuditors(str) {
                if (str) {
                    this.loadingAuditors = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.administrator = str
                    }
                    await this.$http
                        .get("admin/admin", {
                            params: params,
                        })
                        .then(res => {
                            this.auditorItems = res.body.data
                        })
                        .catch(err => {
                            this.auditorItems = []
                        })
                        .finally(end => {
                            this.loadingAuditors = false
                        })
                }
            },

            async getSexes() {
                this.loadingSex = true
                let params = {}
                await this.$http
                    .get("admin/sex", {
                        params: params,
                    })
                    .then(res => {
                        this.sexItems = res.body.data
                    })
                    .catch(err => {
                        this.sexItems = []
                    })
                    .finally(end => {
                        this.loadingSex = false
                    })
            },
            async getTypeClients() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/type_client", {
                        params: params,
                    })
                    .then(res => {
                        this.typeClientItems = res.body.data
                    })
                    .catch(err => {
                        this.typeClientItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getTypeActivities() {
                this.loadingTypeActivity = true
                let params = {}
                await this.$http
                    .get("admin/type_activity", {
                        params: params,
                    })
                    .then(res => {
                        this.type_activityItems = res.body.data
                    })
                    .catch(err => {
                        this.type_activityItems = []
                    })
                    .finally(end => {
                        this.loadingTypeActivity = false
                    })
            },
            async getAttractionChannels() {
                this.loadingAttractionChannel = true
                let params = {}
                await this.$http
                    .get("admin/attraction_channel", {
                        params: params,
                    })
                    .then(res => {
                        this.attraction_channelItems = res.body.data
                    })
                    .catch(err => {
                        this.attraction_channelItems = []
                    })
                    .finally(end => {
                        this.loadingAttractionChannel = false
                    })
            },
            async getTypeTransports() {
                this.loadingTypeTransport = true
                let params = {}
                await this.$http
                    .get("admin/type_transport", {
                        params: params,
                    })
                    .then(res => {
                        this.type_transportItems = res.body.data
                    })
                    .catch(err => {
                        this.type_transportItems = []
                    })
                    .finally(end => {
                        this.loadingTypeTransport = false
                    })
            },
            async getUved() {
                var _this = this;
                this.progress = 0
                this.loadingUved = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/uved/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                         this.uved_id = res.body.data.id
                         this.edit_type_client = res.body.data.type_client.id
                         this.uved_uuid = res.body.data.uuid
                         this.favorite = res.body.data.favorite
                         this.type_uved = res.body.data.type_uved
                         this.show_responsible = res.body.data.responsibles
                         this.responsible = res.body.data.responsibles
                         this.type_client = res.body.data.type_client
                         this.author = res.body.data.author
                         this.text_uved = res.body.data.note
                         this.company_name = res.body.data.company_name
                         this.uved_name = res.body.data.uved_name
                         this.first_name = res.body.data.first_name
                         this.last_name = res.body.data.last_name
                         this.middle_name = res.body.data.middle_name
                         this.bin_inn = res.body.data.bin_inn
                         this.phones = res.body.data.phones
                         this.emails = res.body.data.emails
                         this.sites = res.body.data.sites
                         this.addresses = res.body.data.addresses
                         this.sex = res.body.data.sex
                         this.type_activity = res.body.data.type_activity
                         this.foundation_day = res.body.data.foundation_day
                         this.attraction_channel = res.body.data.attraction_channel
                         this.supplier = res.body.data.supplier
                         this.products = res.body.data.products
                         this.client_code = res.body.data.client_code
                         this.supplier_address = res.body.data.supplier_address
                         this.type_transport = res.body.data.type_transport
                         this.tnved = res.body.data.tnved
                         let company = res.body.data.company
                         this.company_top = res.body.data.company
                         if (company && company.id) {
                            this.company = company.id
                            this.companyItems = [res.body.data.company]
                         } else {
                            this.companyItems = []
                         }
                        this.created_at = res.body.data.created_at
                        this.accompanying_document_urls = res.body.data.documents
                        this.permissions = res.body.data.permissions

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_uved'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loadingUved = false
                    })
            },
            async saveUvedField(field, value) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if(field === 'phones'){
                    this.phones = this.phones.filter(item => item.phone !== '' && item.phone !== null);
                    this.phones.forEach(function callback(item, key) {
                        if(item.phone!=='' && item.phone!=='null' && item.phone!==null){
                            formData.append(`phones[${key}][phone]`, item.phone)
                            formData.append(`phones[${key}][field]`, item.field)
                        }
                    });
                }
                else if(field === 'addresses'){
                    this.addresses = this.addresses.filter(item => item.address !== '' && item.address !== null);
                    this.addresses.forEach(function callback(item, key) {
                        formData.append(`addresses[${key}][address]`, item.address)
                        formData.append(`addresses[${key}][office]`, item.office)
                    });
                }
                else if(field === 'emails'){
                    this.emails = this.emails.filter(item => item.email !== '' && item.email !== null);
                    this.emails.forEach(function callback(item, key) {
                        formData.append(`emails[${key}]`, item.email)
                    });
                }
                else if(field === 'sites'){
                    if(this.sites.length > 0) {
                        this.sites = this.sites.filter(item => item.url !== '' && item.url !== null);
                        this.sites.forEach(function callback(item, key) {
                            if (item.url && item.url.length) {
                                formData.append(`sites[${key}]`, item.url)
                            }
                        });
                    } else {
                        formData.append(`sites`, '')
                    }
                }
                else{
                    formData.append(field, value)
                }

                if (this.$route.params.id && this.uved_uuid ) {
                    await this.$http
                        .put(`admin/uved/${this.uved_uuid}/field`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('uved_has_been_updated'))
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('uved_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }

            },
            async UvedTextUpdate() {
                this.saveUvedField('note',this.text_uved);
                this.text_uved_edit = false
            },

            async favoriteUpdate(){
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()


                formData.append('uved_uuid', this.uved_uuid)
                formData.append('type', 'uved')

                await this.$http
                    .put(`admin/favorite/${this.uved_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('favorite_has_been_updated'))
                        if(this.favorite){
                            this.favorite = false;
                        }
                        else{
                            this.favorite = true
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('favorite_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },

            async deleteUved() {
                if (confirm(this.$t('delete_uved'))) {
                    var _this = this
                    this.loading = true;

                    await this.$http
                        .delete(`admin/uved/${this.uved_id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('uved_has_been_deleted'))
                            this.$router.push({
                                name: 'uved',
                            })
                        })
                        .catch(err => {
                            this.$toastr.success(this.$t('uved_has_not_been_deleted'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },


            downloadFile(data) {
                fetch(data.url)
                    .then((resp) => resp.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = data.name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.$toastr.success(this.$t('file_download_started'))
                    })
                    .catch(() =>
                        this.$toastr.error(this.$t('file_download_error'))

                    );
            },
            dropFile(event){
                this.files = Array.from(event.dataTransfer.files);
                this.previewFiles();
            },
            previewFiles(){
                let i = this.document_urls.length
                let data = {}
                this.files.forEach((file) => {
                    data = {}
                    data.id = i
                    data.url = URL.createObjectURL(file)
                    data.name = file.name
                    data.blob =  file
                    data.size = file.size
                    data.type = file.type
                    this.document_urls.push(data)
                    i++
                })
                this.dialog_file = true
            },

            deleteFile(item){
                let index =  this.document_urls.indexOf(item)
                this.document_urls.splice(index, 1)
                if(this.document_urls.length === 0){
                    this.dialog_file = false;
                    this.document_urls=[];
                    this.documents=[]
                }
            },
            deleteAccompanyingDocumentFile(item){
                let index =  this.accompanying_document_urls.indexOf(item)
                this.accompanying_document_urls.splice(index, 1)
            },
            deleteAccompanyingDocuments(item){
                let index =  this.accompanying_documents_preview.indexOf(item)
                this.accompanying_documents_preview.splice(index, 1)
                if(this.accompanying_documents_preview.length === 0){
                    this.accompanying_documents_dialog = false;
                    this.accompanying_documents_preview=[];
                    this.accompanying_documents=[]
                }
            },


            async saveUvedFile() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)
                }

                if (this.accompanying_documents_preview && this.accompanying_documents_preview.length > 0) {
                    let is_image = false;
                    for (let i in this.accompanying_documents_preview) {
                        is_image = this.mimeTypeImage(this.accompanying_documents_preview[i].type);
                        formData.append(`type_mime[${i}]`, this.accompanying_documents_preview[i].type)
                        formData.append(`size[${i}]`, this.accompanying_documents_preview[i].size)
                        formData.append(`file_name[${i}]`, this.accompanying_documents_preview[i].name)
                        formData.append(`is_image[${i}]`, is_image ? 1 : null)

                        this.accompanying_documents_preview[i].blob =  await this.convertBlobToBase64(this.accompanying_documents_preview[i].blob)
                        if (this.accompanying_documents_preview[i].blob) {
                            let blob = this.dataURL64toBlob(this.accompanying_documents_preview[i].blob)
                            if (blob) {
                                formData.append(`upfiles[${i}]`, blob, this.accompanying_documents_preview[i].type)
                            }
                        }

                       // formData.append(`upfiles[${i}]`, this.accompanying_documents_preview[i].blob)
                    }
                }

                await this.$http
                    .put(`admin/uved/file/${this.uved_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('uved_has_been_updated'))

                        if (this.accompanying_documents_preview && this.accompanying_documents_preview.length > 0) {
                            for (let i in this.accompanying_documents_preview) {
                                let author = {
                                    "author":{
                                        "name" : this.$auth.user().name
                                    }
                                };
                                Object.assign(this.accompanying_documents_preview[i], author);
                                this.accompanying_document_urls.push(this.accompanying_documents_preview[i])
                            }
                            this.accompanying_documents_dialog = false;
                            this.accompanying_documents_preview=[];
                            this.accompanying_documents=[]
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('file_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },


            async deleteFileServer(item) {
                if (confirm(this.$t('want_to_delete_file'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/uved/file/${item.uuid}`)
                        .then(res => {
                            this.$toastr.success(this.$t('uved_file_has_been_deleted'))
                            this.deleteAccompanyingDocumentFile(item)
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('uved_file_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },

            UvedMessageslWebsocket() {
                //console.log('ddddd' + `App.Models.Admin.${this.$auth.user().id}`)
              //  console.log('ddddd' + `.Event.Chat.${this.uved_uuid}`)
                window.Echo
                    .private(`App.Models.Admin.${this.$auth.user().id}`)
                    .listen(`.Event.UvedChat.${this.uved_uuid}`, (event) => {
                     //   console.log(event)
                        //Если пришло добавление собщение
                        if(event && event.id && event.id > 0) {
                            if (event.comment && event.comment.type) {
                                // if(event.comment.type === 'text') {

                                if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                                    const newMessage = {
                                        [this.$moment().format('YYYY-MM-DD')]:
                                            [
                                                event
                                            ]
                                    }
                                    this.chatMessagesDate = { ...this.chatMessagesDate, ...newMessage };
                                }
                                else{
                                    let index = Number(event.id_array) - 1;
                                    let message_date = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')];
                                    if (typeof message_date[index] != "undefined") {
                                        message_date[index].id = event.id;
                                        //this.chatMessages[index].name = event.name;
                                        message_date[index].comment = event.comment;
                                        message_date[index].created_at = event.created_at;
                                        message_date[index].delivered = event.delivered;
                                        //  this.chatMessages[index].me = event.me;
                                        message_date[index].uuid = event.uuid;
                                    }
                                    else {
                                        message_date.push(event);
                                    }
                                }
                            }

                        }
                        else if(event && event.type && event.type==='delete'){
                            //Если пришло удаление собщение
                            if (event.id_array && event.id_array > 0 && event.date) {
                                //if(event.admin_id !== this.$auth.user().id){
                                this.chatMessagesDate[event.date].splice(event.id_array, 1);
                                // }

                            }
                        }
                    });
                /*
                .notification((notify) => {
                    //console.log('MainLayout notify')
                    console.log(notify)
                    if (notify.type.indexOf('broadcast.message') !== -1) {
                        this.$toastr.success(`${notify.title} ${notify.message}`)
                    }
                })
               */
            },
            handleEnterKey(event) {
                if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {
                    this.SentMessage();
                }
                else{
                    event.preventDefault();
                    if(!event.shiftKey){
                        this.text += '\n';
                    }
                }
            },
            async SentMessage() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if( typeof this.text  === 'undefined' || this.text  === null || this.text === ''){
                    return;
                }

                if (this.language) {
                    formData.append('language', this.language)
                }
                if (this.reply_uuid) {
                    formData.append('reply_uuid', this.reply_uuid)
                }
                if (this.text) {
                    formData.append('message', this.text)
                }
                formData.append('uved_uuid', this.uved_uuid)
                if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                    formData.append('id_array', 1)
                }
                else{
                    formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
                }

                // Add
                await this.$http
                    .post('admin/uved_message', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        let replyMessage = {}
                        if (this.reply_uuid) {
                            if(this.reply_msg.type === 'text'){
                                replyMessage = {
                                    "type":  this.reply_msg.type,
                                    "name": this.reply_name,
                                    "reply_uuid":  this.reply_msg.uuid,
                                    "value": this.reply_msg.value,
                                }
                            }
                            else{
                                replyMessage = {
                                    "type":  this.reply_msg.type,
                                    "name": this.reply_name,
                                    "reply_uuid":  this.reply_msg.uuid,
                                    "value": this.reply_msg.value,
                                    "size": this.reply_msg.size,
                                    "type_mime": this.reply_msg.type_mime,
                                    "file_type": this.reply_msg.file_type,
                                    "file_name": this.reply_msg.file_name,
                                }
                            }

                        }

                        if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {

                            const newMessage = {
                                [this.$moment().format('YYYY-MM-DD')]:
                                    [
                                        {
                                            "id": Number(new Date().getTime()) + 1,
                                            "uuid": "uuid",
                                            "me": true,
                                            "comment": {"type": "text", "value": this.text},
                                            "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                            "delivered": false,
                                            "name": this.$auth.user().name,
                                            "phone": this.$auth.user().phone,
                                            "photo": this.$auth.user().photo,
                                            "reply": replyMessage
                                        }
                                    ]
                            }
                            this.chatMessagesDate = { ...this.chatMessagesDate, ...newMessage };
                        }
                        else{
                            this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].push({
                                "id": Number(new Date().getTime()) + this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1,
                                "uuid": "uuid",
                                "me": true,
                                "comment": {"type": "text", "value": this.text},
                                "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                "delivered": false,
                                "name": this.$auth.user().name,
                                "phone": this.$auth.user().phone,
                                "photo": this.$auth.user().photo,
                                "reply": replyMessage
                            });
                        }
                        this.text = ''
                        this.count_messages++;
                        this.replyClose();

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('message_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            async getMessages() {
                this.loadingMessage = true;
                let params = {};
                params.uved_uuid = this.uved_uuid;

                await this.$http
                    .get("admin/uved_message", {
                        params: params,
                    })
                    .then(res => {
                        if(res && res.body && res.body.data)
                        {
                            this.chatMessagesDate = res.body.data
                        }
                        else{
                            this.chatMessagesDate = []
                        }

                    })
                    .catch(err => {
                        this.chatMessagesDate = []
                        this.$toastr.error(this.$t('failed_to_get_list_messages'))
                    })
                    .finally(end => {
                        this.loadingMessage = false
                    })
            },
            async SentMessageFile() {
                var _this = this;
                let formData, blob;
                this.dialog_file = false;
                this.documents=[]
                let is_image = false;


                let id_array = 0;
                for (const file of this.document_urls) {

                    let replyMessage = {}
                    if (this.reply_uuid) {
                        if(this.reply_msg.type === 'text'){
                            replyMessage = {
                                "type":  this.reply_msg.type,
                                "name": this.reply_name,
                                "reply_uuid":  this.reply_msg.uuid,
                                "value": this.reply_msg.value,
                            }
                        }
                        else{
                            replyMessage = {
                                "type":  this.reply_msg.type,
                                "name": this.reply_name,
                                "reply_uuid":  this.reply_msg.uuid,
                                "value": this.reply_msg.value,
                                "size": this.reply_msg.size,
                                "type_mime": this.reply_msg.type_mime,
                                "file_type": this.reply_msg.file_type,
                                "file_name": this.reply_msg.file_name,
                            }
                        }

                    }

                    is_image = this.mimeTypeImage(file.type);
                    if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                        id_array = 1;
                        const newMessage = {
                            [this.$moment().format('YYYY-MM-DD')]:
                                [
                                    {
                                        "id": Number(new Date().getTime()) + 1,
                                        "uuid": "uuid",
                                        "me": true,
                                        "comment": is_image ? {"type": "image", "value": file.url} : {"type": "file", "value": file.url, "file_name":file.name, "size":file.size},
                                        "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                        "delivered": false,
                                        "name": this.$auth.user().name,
                                        "phone": this.$auth.user().phone,
                                        "photo": this.$auth.user().photo,
                                        "reply": replyMessage
                                    }
                                ]
                        }
                        this.chatMessagesDate = { ...this.chatMessagesDate, ...newMessage };
                    }
                    else{
                        id_array = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1;
                        this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].push({
                            "id": Number(new Date().getTime()) + (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length) + 1,
                            "uuid": "uuid",
                            "me": true,
                            "comment": is_image ? {"type": "image", "value": file.url} : {"type": "file", "value": file.url, "file_name":file.name, "size":file.size},
                            "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                            "delivered": false,
                            "name": this.$auth.user().name,
                            "phone": this.$auth.user().phone,
                            "photo": this.$auth.user().photo,
                            "reply": replyMessage
                        });
                    }

                    formData = new FormData();
                    if (this.reply_uuid) {
                        formData.append('reply_uuid', this.reply_uuid)
                    }
                    formData.append('id_array', id_array)
                    formData.append('uved_uuid', this.uved_uuid)
                    formData.append('type_mime', file.type)
                    formData.append('size', file.size)
                    formData.append('file_name', file.name)
                    formData.append('is_image', is_image ? 1 : null)
                    file.blob =  await this.convertBlobToBase64(file.blob)
                    if (file.blob) {
                        blob = this.dataURL64toBlob(file.blob)
                        if (blob) {
                            formData.append('file', blob, file.type)
                        }
                    }

                    // Add
                    this.$http
                        .post('admin/uved_message/file', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(res => {
                            var index = this.chatMessages.length + 1
                            if (typeof this.chatMessages[index] != "undefined") {
                                this.chatMessages[index].delivered = true;
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('page_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            var index = this.chatMessages.length + 1
                            if (typeof this.chatMessages[index] != "undefined") {
                                this.chatMessages[index].delivered = true;
                            }
                        })


                }
                this.replyClose()
                this.document_urls=[];
                this.count_messages++;
            },
            async deleteMessages(msg,date) {
                this.loading = true;
                let params = {};
                let idx = this.chatMessagesDate[date].indexOf(msg);
                params.uved_uuid = this.uved_uuid;
                params.message_uuid = msg.uuid;
                params.date = date;
                if (idx !== -1) {
                    params.id_array = idx;
                }

                await this.$http
                    .delete(`admin/uved_message/${this.uved_uuid}`,{
                        params: params,
                    })
                    .then(res => {

                        //let index = this.chatMessagesDate[date].findIndex(el => el.uuid === "4fb4bc6b-9a77-40fb-99aa-865c73a4b004");
                        if (idx !== -1) {
                            this.chatMessagesDate[date].splice(idx, 1);
                        }
                        this.$toastr.success(this.$t('message_has_been_deleted'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('message_has_not_been_deleted'))
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },

            accompanyingDocumentsPreviewFiles(){
                let i = 0;
                let data = {}
                this.accompanying_documents.forEach((file) => {
                    data = {}
                    data.id = i
                    data.url = URL.createObjectURL(file)
                    data.name = file.name
                    data.blob =  file
                    data.size = file.size
                    data.type = file.type
                    this.accompanying_documents_preview.push(data)
                    i++
                })
                 this.accompanying_documents_dialog = true
            },


            async editStatus(status) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)

                }
                formData.append('uved_status', status)


                await this.$http
                    .put(`admin/uved/status/${this.uved_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('uved_has_been_updated'))
                        this.uved_status = res.body.data.uved_status_id
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('file_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            async addStatusApproval(status) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.language) {
                    formData.append('language', this.language)

                }
                formData.append('uved_status', status)
                if(this.reject_text){
                    formData.append('reject_text', this.reject_text)
                }



                await this.$http
                    .post(`admin/uved/status/${this.uved_uuid}/approval`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('uved_has_been_updated'))
                        this.getUved();
                        this.is_agreed = res.body.data.is_agreed
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('file_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            showCompany(uuid) {
                this.$router.push({
                    name: 'company.show',
                    params: {
                        id: uuid
                    }
                })
            },
            async sentInvite(email) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (email) {
                    formData.append('email', email)
                }
                if (this.$route.params.id) {
                    formData.append('uved_uuid', this.$route.params.id)
                }

                // Add
                await this.$http
                    .post('admin/invite', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('invite_has_been_added'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('invite_has_not_been_added'))
                        this.dialogFileError = true
                        this.textFileError = err.body.message;

                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }

                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })

            },
        }
    }
</script>

<style scoped lang="scss">
.v-card--link:before {
    background: none;
}

>>>.heightTable td {
    height: 50px !important;
}

.select_border_none >>> .v-input__slot::before {
    border-style: none !important;
}

.text-label{
    display: block;
    color: #a9a9a9;
}

.wrapper-progressBar {
    width: 100%
}

.progressBar {
}

.progressBar li {
    list-style-type: none;
    float: left;
    width: 33%;
    position: relative;
    text-align: center;
}

.progressBar li:before {
    content: " ";
    line-height: 10px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-color: white;

}

.progressBar li:after {
    content: "";
    position: absolute;
    width: 90%;
    height: 4px;
    background-color: #ddd;
    top: 3px;
    left: -45%;

}

.progressBar li:first-child:after {
    content: none;

}

.progressBar li.active {
    color: #265898;

}

.progressBar li.active:before {
    border-color: #265898;
    background-color: #265898;

}

.progressBar .active:after {
    background-color: #265898;
}

.color_black{
    color: #000;
}
.color_dodgerblue{
    color: #265898;
}


.add_text{
    cursor: pointer;
    border-bottom: dashed 1px;
}

.client-phone {
    padding-bottom: $grid-gutter / 2;

    &:last-child {
        padding-bottom: 0;
    }
}
</style>

<style lang="scss">
.uved-detail {
    .tab-list {
        flex-wrap: wrap;

        .tab {
            width: calc(33.33333% - #{$grid-gutter});

            @media #{map-get($display-breakpoints, 'lg-and-up')} {
                width: calc(25% - #{$grid-gutter});
            }
        }
    }
}
</style>
