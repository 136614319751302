<script>
import {mapGetters} from "vuex";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import TextInput from "@/components/Form/TextInput.vue";
import Bank from "@/components/Uved/Bank";

export default {
    name: "Banks",
    components: {
        TextInput,
        Bank,
        ValidationProvider,
        ValidationObserver
    },

    props: {
        uuid: {
            type: String,
            required: false
        },
        editable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            editingFields: {},
            dialog: false,
            banks: [],
            bank: {
                bank_name: '',
                bank_address: '',
                bik: '',
                swift: '',
                kpp: '',
                account_number: '',
                correspondent_account_number: '',
                currency_id: null,
                comment: '',
            },
            currencyItems: [],
            loading: false,
        }
    },

    watch: {
        uuid: {
            immediate: true,
            handler() {
                if (this.uuid) {
                    this.fetchBanks();
                }
            }
        }
    },

    mounted() {
         this.getCurrencies();
    },

    computed: {
        ...mapGetters({
            userBlock: 'userBlock'
        })
    },

    methods: {
        fetchBanks() {
            this.$http.get(`admin/uved/${this.uuid}/bank`)
                .then((response) => {
                    this.banks = response.body.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        addBank() {
            this.$http.post(`admin/uved/${this.uuid}/bank`, this.bank)
                .then(response => {
                    this.dialog = false;
                    this.fetchBanks();
                })
                .catch(error => {
                    console.log(error);
                });
        },

        saveBank(bank) {
            this.$http.put(`admin/uved/${this.uuid}/bank/${bank.id}`, bank)
                .then(response => {
                    this.fetchBanks();
                })
                .catch(error => {
                    console.log(error);
                });
        },

        delBank(bank) {
            this.$http.delete(`admin/uved/${this.uuid}/bank/${bank.id}`)
                .then(response => {
                    this.fetchBanks();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async getCurrencies() {
            let params = {};
            params.filter = 'search';
            await this.$http
                .get(`admin/currency`, {
                    params: params,
                })
                .then(res => {
                    this.currencyItems = res.body.data
                })
                .catch(err => {
                    this.currencyItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_currencies'))
                });
        },
    },
}
</script>

<template>
    <div>
        <v-col cols="12" class="pa-0">
            <v-card style="margin-top: 25px">
                <v-card-title class="d-flex">
                    <div class="participants mr-auto">{{ $t('uved_bank.banks') }}</div>
                    <div class="">
                        <v-icon
                            size="18"
                            color="secondary"
                            class="add_icon"
                            :disabled="!editable"
                            @click="dialog = true"
                        >
                            mdi-plus-circle-outline
                        </v-icon>
                    </div>
                </v-card-title>
                <v-card-text class="">
                    <template v-if="banks && banks.length > 0">
                        <template v-for="item in banks">
                            <Bank :data="item" @save-bank="saveBank" @del-bank="delBank"/>
                        </template>
                    </template>
                    <template v-else>
                        {{$t('uved_bank.no_banks')}}
                    </template>
                </v-card-text>
            </v-card>
        </v-col>
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="700"
            v-model="dialog"
        >
            <ValidationObserver v-slot="{ invalid }">
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('uved_bank.banks_edit') }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialog = false" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-0 pt-5" cols="12">
                                <ValidationProvider :ref="bank.bank_name" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                    <TextInput
                                        hide-label
                                        :error="valid === false"
                                        :error-messages="errors"
                                        id="bank_name"
                                        v-model="bank.bank_name"
                                        :label="$t('uved_bank.bank_name')"
                                    ></TextInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0 pt-5" cols="12">
                                <ValidationProvider :ref="bank.bank_address" rules="min:1|max:255" v-slot="{ errors, valid }">
                                    <TextInput
                                        hide-label
                                        :error="valid === false"
                                        :error-messages="errors"
                                        id="bank_address"
                                        v-model="bank.bank_address"
                                        :label="$t('uved_bank.bank_address')"
                                    ></TextInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0 pt-5" cols="12">
                                <ValidationProvider :ref="bank.bank_name" rules="min:1|max:255" v-slot="{ errors, valid }">
                                    <TextInput
                                        hide-label
                                        :error="valid === false"
                                        :error-messages="errors"
                                        id="bank_bik"
                                        v-model="bank.bik"
                                        :label="$t('uved_bank.bik')"
                                    ></TextInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0 pt-5" cols="12">
                                <ValidationProvider :ref="bank.bank_name" rules="max:255" v-slot="{ errors, valid, value }">
                                    <TextInput
                                        hide-label
                                        id="bank_swift"
                                        :error="valid === false"
                                        :error-messages="errors"
                                        v-model="bank.swift"
                                        :label="$t('uved_bank.swift')"
                                    ></TextInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0 pt-5" cols="12">
                                <ValidationProvider :ref="bank.bank_name" rules="min:1|max:255" v-slot="{ errors, valid }">
                                    <TextInput
                                        hide-label
                                        id="bank_kpp"
                                        v-model="bank.kpp"
                                        :error="valid === false"
                                        :error-messages="errors"
                                        :label="$t('uved_bank.kpp')"
                                    ></TextInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0 pt-5" cols="12">
                                <ValidationProvider :ref="bank.bank_name" rules="min:1|max:255" v-slot="{ errors, valid }">
                                    <TextInput
                                        hide-label
                                        id="bank_account_number"
                                        :error="valid === false"
                                        :error-messages="errors"
                                        v-model="bank.account_number"
                                        :label="$t('uved_bank.account_number')"
                                    ></TextInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0 pt-5" cols="12">
                                <ValidationProvider :ref="bank.bank_name" rules="min:1|max:255" v-slot="{ errors, valid }">
                                    <TextInput
                                        hide-label
                                        id="bank_correspondent_account_number"
                                        :error="valid === false"
                                        :error-messages="errors"
                                        v-model="bank.correspondent_account_number"
                                        :label="$t('uved_bank.correspondent_account_number')"
                                    ></TextInput>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0 pt-5" cols="12">



                                <ValidationProvider ref="bank.currency_id" rules="required" v-slot="{ errors, valid }">
                                    <v-select v-model="bank.currency_id" :items="currencyItems"
                                              item-text="letter_code" item-value="id"
                                              :error-messages="errors"
                                              :disabled="loading"
                                              :hide-details="(valid || bank.currency=== null) ? true : false"

                                              :label="$t('currency')"
                                              outlined
                                              solo
                                              flat
                                              dense
                                              height="46"
                                              color="primary"
                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                              clearable
                                    ></v-select>
                                </ValidationProvider>

                            </v-col>
                            <v-col class="py-0 pt-5" cols="12">
                                <ValidationProvider :ref="bank.bank_name" rules="min:1|max:255" v-slot="{ errors, valid }">
                                    <TextInput
                                        hide-label
                                        id="bank_comment"
                                        :error="valid === false"
                                        :error-messages="errors"
                                        v-model="bank.comment"
                                        :label="$t('uved_bank.comment')"
                                    ></TextInput>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-5">
                        <v-spacer></v-spacer>
                        <v-btn text :disabled="invalid" color="primary" @click="addBank">{{ $t('save') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-dialog>
    </div>
</template>
